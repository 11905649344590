import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const hasNumber = /\d/;
  const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/;

  const checkPasswordStrength = (password) => {
    if (password.length === 0) {
      return '';
    } else if (password.length < 4) {
      return 'Weak : Must contain at least 8 characters';
    } else if (password.length < 8 || !hasNumber.test(password) || !hasSymbol.test(password)) {
      return 'Medium : Must contain symbols and numbers';
    } else {
      return 'Strong : Your password is looking good';
    }
  };

  const passwordStrength = checkPasswordStrength(password);

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 'Weak : Must contain at least 8 characters':
        return 'text-red-500';
      case 'Medium : Must contain symbols and numbers':
        return 'text-yellow-500';
      case 'Strong : Your password is looking good':
        return 'text-green-500';
      default:
        return '';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setLoading(true);

    if (password !== confirmPassword) {
      setLoading(false);
      setError('Passwords do not match.');
      return;
    }

    axios.post(`${BACKEND_URL}/auth/reset-password/${token}`, { password })
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          setMessage('Password reset successfully.');
          setTimeout(() => navigate('/login'), 3000);
        } else {
          setError(response.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        setError('An error occurred. Please try again.');
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-4">Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6 relative">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">New Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Enter new password"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute right-3 bottom-1 transform -translate-y-1/2 cursor-pointer text-gray-400"
              title={showPassword ? 'Hide password' : 'Show password'}
              onMouseDown={(e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
            </div>
            {password && (
              <p className={`text-sm mt-2 ${getPasswordStrengthColor()} mb-4`}>
                {passwordStrength}
              </p>
            )}
          <div className="mb-6 relative">
            <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">Confirm New Password</label>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm new password"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div
              className="absolute right-3 bottom-1 transform -translate-y-1/2 cursor-pointer text-gray-400"
              onMouseDown={(e) => {
                e.preventDefault();
                setShowConfirmPassword(!showConfirmPassword)
              }}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {message && <p className="text-green-500 text-sm mb-4">{message}</p>}
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <div className="flex items-center justify-center">
            <button type="submit" className="bg-white text-[#FE4202] font-bold py-2 px-4 border border-[#FE4202] rounded focus:outline-none focus:shadow-outline hover:bg-[#FE4202] hover:text-white transition-colors duration-300" disabled={loading}>
              {loading ? 'Resetting...' : 'Reset Password'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;