import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineDownload, AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import ExcelJS from 'exceljs';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Tab } from '@headlessui/react';

const DockPopupInEdit = ({ dockData, dockSmile, onClose }) => {
    console.log(dockData)
    // const dockData = [{
    //     "pdb_id": "1mfg",
    //     "dockData": [
    //         {
    //             "model": 1,
    //             "binding affinity": -3.659,
    //             "rmsd l.b.": 0,
    //             "rmsd u.b.": 0
    //         },
    //         {
    //             "model": 2,
    //             "binding affinity": -3.565,
    //             "rmsd l.b.": 28.929,
    //             "rmsd u.b.": 30.342
    //         },
    //         {
    //             "model": 3,
    //             "binding affinity": -3.496,
    //             "rmsd l.b.": 3.023,
    //             "rmsd u.b.": 4.056
    //         },
    //         {
    //             "model": 4,
    //             "binding affinity": -3.352,
    //             "rmsd l.b.": 25.832,
    //             "rmsd u.b.": 27.707
    //         },
    //         {
    //             "model": 5,
    //             "binding affinity": -3.2,
    //             "rmsd l.b.": 2.162,
    //             "rmsd u.b.": 2.694
    //         },
    //         {
    //             "model": 6,
    //             "binding affinity": -3.161,
    //             "rmsd l.b.": 33.654,
    //             "rmsd u.b.": 35.228
    //         },
    //         {
    //             "model": 7,
    //             "binding affinity": -2.879,
    //             "rmsd l.b.": 12.858,
    //             "rmsd u.b.": 14.417
    //         },
    //         {
    //             "model": 8,
    //             "binding affinity": -2.845,
    //             "rmsd l.b.": 28.207,
    //             "rmsd u.b.": 29.398
    //         },
    //         {
    //             "model": 9,
    //             "binding affinity": -2.711,
    //             "rmsd l.b.": 1.516,
    //             "rmsd u.b.": 1.991
    //         }
    //     ]
    // }]
    // const dockData = [ { 
    //     "pdb_id": "1mfg",
    //     "dockData": [
    //         {
    //             "model": 1,
    //             "binding affinity": -3.659,
    //             "rmsd l.b.": 0,
    //             "rmsd u.b.": 0
    //         },
    //         {
    //             "model": 2,
    //             "binding affinity": -3.565,
    //             "rmsd l.b.": 28.929,
    //             "rmsd u.b.": 30.342
    //         } 

    //     ]
    // },{ 
    //     "pdb_id": "3mfg", 
    //     "dockData": [
    //         {
    //             "model": 1,
    //             "binding affinity": -0.659, 
    //             "rmsd l.b.": 0,
    //             "rmsd u.b.": 0
    //         },
    //         {
    //             "model": 2,
    //             "binding affinity": -1.565, 
    //             "rmsd l.b.": 8.929, 
    //             "rmsd u.b.": 3.342 
    //         } 

    //     ]
    // }];
    const [currentIndex, setCurrentIndex] = useState(0);

    // Get the current docking data based on the currentIndex
    const currentDock = dockData[currentIndex];

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % dockData.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex - 1 + dockData.length) % dockData.length
        );
    };

    // Function to handle Excel download
    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Docking Data');

        worksheet.columns = [
            { header: 'Model', key: 'model', width: 20 },
            { header: 'Binding Affinity (kcal/mol)', key: 'binding_affinity', width: 30 },
            { header: 'RMSD Lower Bound', key: 'rmsd_lb', width: 20 },
            { header: 'RMSD Upper Bound', key: 'rmsd_ub', width: 20 },
        ];

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        currentDock.dockData.forEach((data) => {
            worksheet.addRow({
                model: data.model,
                binding_affinity: data['binding_affinity'],
                rmsd_lb: data['rmsd_lb'],
                rmsd_ub: data['rmsd_ub'],
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `docking_data_${currentDock.pdb_id}.xlsx`;
        link.click();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white p-4 rounded shadow-md max-w-4xl w-full">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-red-500 hover:text-gray-700 transition"
                >
                    <AiOutlineClose className="w-4 h-4" title="close" />
                </button>

                <div className="flex justify-center mb-2 text-black overflow-y-auto scrollbar-hide max-w-[98%]">
                    <p className="text-lg font-bold flex flex-wrap mb-2">
                        Docked
                        <span className="overflow-x-auto max-w-[250px] whitespace-nowrap custom-scrollbar mx-1">
                            {dockSmile}
                        </span>
                        against structure {currentDock.pdb_id}
                    </p>
                </div>

                <Tab.Group defaultIndex={currentIndex}>
                    <Tab.List className="flex p-1 space-x-1 rounded-xl">
                        {dockData.map((_, index) => (
                            <Tab
                                key={index}
                                className={({ selected }) =>
                                    `w-full py-2.5 text-sm leading-5 font-medium focus:outline-none ${
                                        selected
                                            ? 'text-[#FE4202] border-b-2 border-[#FE4202]'
                                            : 'hover:text-[#FE4202]'
                                    }`
                                }
                                onClick={() => setCurrentIndex(index)} // Change index on tab click
                            >
                                {dockData[index].pdb_id}
                            </Tab>
                        ))}
                    </Tab.List>

                    <Tab.Panels className="mt-2">
                        {dockData.map((dock, index) => (
                            <Tab.Panel key={index} className="bg-white rounded-xl p-3 shadow min-h-[200px]">
                                <table className="min-w-full border">
                                    <thead>
                                        <tr className="bg-[#EEEFF2]">
                                            <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                                Model
                                            </th>
                                            <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                                Binding Affinity (kcal/mol)
                                            </th>
                                            <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                                RMSD LOWER BOUND
                                            </th>
                                            <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                                RMSD UPPER BOUND
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dock.dockData.map((data) => (
                                            <tr key={data.model}>
                                                <td className="border px-4 py-2 text-sm text-gray-700">{data.model}</td>
                                                <td className="border px-4 py-2 text-sm text-gray-700">{data['binding_affinity']}</td>
                                                <td className="border px-4 py-2 text-sm text-gray-700">{data['rmsd_lb']}</td>
                                                <td className="border px-4 py-2 text-sm text-gray-700">{data['rmsd_ub']}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>

                <div className="flex justify-end items-center mt-4">
                    <button
                        className="px-3 py-1 ml-3 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                        onClick={handleDownload}
                    >
                        <AiOutlineDownload className="w-5 h-5 text-gray-700" title="Download" />
                    </button>
                </div>
            </div>
        </div>
    );
};
export default DockPopupInEdit