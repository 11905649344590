import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FiThumbsUp, FiThumbsDown, FiCopy, FiRefreshCcw, FiEdit } from 'react-icons/fi';
import logo2 from '../../Assets/logo2.png';
import SearchInput from './SearchInput';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import { BiErrorCircle } from "react-icons/bi";
import { sessionQueryID } from '../../redux/Actions/action';
import { FaSpinner } from 'react-icons/fa';

const MESSAGES_PER_LOAD = 10;

const LeftInterface = ({ messages }) => {
  const [messageRefs, setMessageRefs] = useState([]);
  const [timeoutOccurred, setTimeoutOccurred] = useState(false);
  const messagesEndRef = useRef(null);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const sessQueryID = useSelector((state) => state.sessionQueryID);
  const dispatch = useDispatch();

  const [visibleCount, setVisibleCount] = useState(MESSAGES_PER_LOAD);
  const containerRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    // Initialize refs for each message
    setMessageRefs((messageRefs) =>
      Array(messages.length)
        .fill()
        .map((_, i) => messageRefs[i] || React.createRef())
    );
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.Query_ID) {
        // Dispatch the action to update sessionQueryID in Redux with the last message's Query_ID
        dispatch(sessionQueryID(lastMessage.Query_ID));
      }
    }
  }, [messages, dispatch]);
  //   console.log(sessQueryID)

  // Scroll to the message corresponding to sessQueryID
  useEffect(() => {
    const index = messages.findIndex(
      (message) => {
        // console.log(message.Query_ID);
        // console.log(sessQueryID);
        return message.Query_ID === sessQueryID;
      });
    // if ( index !== -1 && messageRefs[index] && messageRefs[index].current)
    if (index !== -1 && messageRefs[index] && messageRefs[index].current) {
      messageRefs[index].current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sessQueryID, messageRefs, messages]);

  const createMarkup = (text) => {
    // Convert markdown to HTML
    const htmlContent = marked(text);

    // Sanitize the HTML content
    const sanitizedContent = DOMPurify.sanitize(htmlContent);

    // Regular expression to find LaTeX expressions enclosed in \[...\]
    const latexRegex = /\[(.*?)\]/g;
    // Replace each LaTeX expression with MathJax-compatible syntax
    const updatedContent = sanitizedContent.replace(latexRegex, (match, p1) => {
      return `\\[${p1}\\]`;
    });
    // // Render the content with MathJax
    // MathJax.typeset();
    // Return the object with __html property
    return { __html: updatedContent };
  };
  // useEffect(() => {
  //   MathJax.typeset();
  // }, [messages]);

  // function replaceLatexWithMathJax() {
  //   // Get the content of the div
  //   let content = document.getElementById('content').innerHTML;

  //   // Regular expression to find LaTeX expressions enclosed in \[...\]
  //   const latexRegex = /\[(.*?)\]/g;

  //   // Replace each LaTeX expression with MathJax-compatible syntax
  //   const updatedContent = content.replace(latexRegex, (match, p1) => {
  //     return `\\[${p1}\\]`;
  //   });

  //   // Update the div with the new content
  //   document.getElementById('content').innerHTML = updatedContent;

  //   // Render the content with MathJax
  // MathJax.typeset();
  // }

  // // Call the function to replace LaTeX expressions and render them
  // replaceLatexWithMathJax();
  // Timeout effect for error display after 60 seconds
  useEffect(() => {
    if (messages.some((message) => !message.response)) {
      const timer = setTimeout(() => {
        setTimeoutOccurred(true);
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [messages]);

  // Handle scrolling to load more messages
  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop } = containerRef.current;
      if (scrollTop === 0 && visibleCount < messages.length && !isLoadingMore) {
        setIsLoadingMore(true);
        // Simulate loading delay
        setTimeout(() => {
          setVisibleCount((prev) => Math.min(prev + MESSAGES_PER_LOAD, messages.length));
          setIsLoadingMore(false);
          // Optional: Maintain scroll position after loading more messages
          // This can be implemented using scrollHeight before and after loading
        }, 500); // Adjust delay as needed
      }
    }
  }, [visibleCount, messages.length, isLoadingMore]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  // Determine the messages to display
  const visibleMessages = messages.slice(-visibleCount);

  return (
    <div className="flex flex-col h-full w-400">
      <div className="flex-grow p-4 overflow-y-auto scrollbar-hide" ref={containerRef}
      >
        {isLoadingMore && (
          <div className="flex justify-center my-2">
            <FaSpinner className="animate-spin text-gray-600 h-6 w-6" /> {/* Spinner */}
          </div>
        )}
        {visibleMessages.map((message, index) => (
          <div key={message.Query_ID} ref={messageRefs[index]}>
            <div
              className={`flex  ${message.QueryName ? 'justify-end ' : 'justify-start'
                } mb-4 mt-2`}
            >
              {/* {message.QueryName && (
                <button className="mr-4 pl-2 text-[#49536E]">
                  <FiEdit size={16} />
                </button>
              )} */}
              <div className="p-1 flex justify-end mb-4 rounded-lg bg-[#EEEFF2] text-black shadow-md">
                <div className="text-start break-all overflow-hidden whitespace-normal prose max-w-[100%] p-1 px-2 [&_p]:mt-1 [&_p]:mb-1"
                  dangerouslySetInnerHTML={{
                    __html: message.QueryName,
                  }}
                />
              </div>
            </div>
            <div
              className={`flex ${message.response ? 'justify-start' : 'justify-end'
                } mb-4`}
            >
              <div
                className={`flex ${message.response ? 'justify-start' : 'justify-end'
                  } mb-4`}
              >
                {message.response === 'Error' || timeoutOccurred ? (
                  <BiErrorCircle title='Error' className="h-8 w-8 text-red-600 mr-2" />
                ) : (
                  <img
                    src={logo2}
                    alt="Bot"
                    className="h-8 w-8 rounded-full mr-2"
                  />
                )}
                <div
                  className={`p-1 flex justify-end rounded-lg bg-[#EEEFF2]  shadow-md text-black ${!message.response ? 'items-center' : ''
                    }`}
                >
                  {!message.response ? (
                    !timeoutOccurred ? (
                      <div className="flex items-center text-surface dark:text-white">
                        <strong>Loading...</strong>
                      </div>
                    ) : (
                      <div className="text-red-600 text-start break-all overflow-hidden whitespace-normal">
                        <strong>"Oops! Looks like things are moving a bit slowly. Let's give it another go in a moment"</strong>
                      </div>
                    )
                  ) : message.response === 'Error' ? (
                    <div className="text-red-600 text-start break-all overflow-hidden whitespace-normal">
                      <strong>Error:</strong> There was a problem processing your request. Please try again later.
                    </div>
                  ) : (
                    <div
                      className="text-start break-all overflow-hidden prose max-w-[100%] p-2 [&_p]:mt-1 [&_p]:mb-1"
                      dangerouslySetInnerHTML={createMarkup(message.response || '')}
                    />
                  )}
                </div>
              </div>
            </div>

            {message.response && (
              <div className="flex ml-10 items-center justify-start mt-2 mb-4 pb-2">
                <div className="flex space-x-2 text-[#49536E]">
                  <button
                    className={`hover:text-[#49536E] ${!buttonEnabled ? 'cursor-not-allowed text-gray-400' : ''}`}
                    disabled={!buttonEnabled}
                    title={buttonEnabled ? 'Like' : 'Button is disabled'}
                  >
                    <FiThumbsUp title="Like" size={13} />
                  </button>

                  <button
                    className={`hover:text-[#49536E] ${!buttonEnabled ? 'cursor-not-allowed text-gray-400' : ''}`}
                    disabled={!buttonEnabled}
                    title={buttonEnabled ? 'Like' : 'Button is disabled'}>
                    <FiThumbsDown title="Dislike" size={13} />
                  </button>
                  <button
                    className={`hover:text-[#49536E] ${!buttonEnabled ? 'cursor-not-allowed text-gray-400' : ''}`}
                    disabled={!buttonEnabled}
                    title={buttonEnabled ? 'Like' : 'Button is disabled'}>
                    <FiCopy title="Copy" size={13} />
                  </button>
                  <button
                    className={`hover:text-[#49536E] ${!buttonEnabled ? 'cursor-not-allowed text-gray-400' : ''}`}
                    disabled={!buttonEnabled}
                    title={buttonEnabled ? 'Like' : 'Button is disabled'}>
                    <FiRefreshCcw title='Regenerate' size={13} />
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-4">
        <div className="relative w-full">
          <SearchInput />
        </div>
      </div>
    </div>
  );
};

export default LeftInterface;
