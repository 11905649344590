// src/components/DrugProteinPair.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableView from './TableView';
import AdmetPopup from './AdmetPopup';
import { fetchParameters } from '../../redux/Actions/action';
import MoleculeEditorPopup from './MoleculeEditorPopup';
import OCL from 'openchemlib';
import DockingPopup from './DockingPopup';
import db from '../../db'; // Import Dexie instance
import axios from 'axios';

const DrugProteinPair = ({ data, Query_ID }) => {
    const { drugDetails, proteinDetails } = data || {};
    const [predTempResult, setPredTempResult] = useState('');
    const [predTempAdmet_result, setPredAdmet_result] = useState('');
    const [error, setError] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [selectedAdmet, setSelectedAdmet] = useState(null);
    const [selectedDrugName, setSelectedDrugName] = useState('');
    const [editSmiles, setEditSmiles] = useState(null);
    const [dockSmiles, setDockSmiles] = useState(null);
    const [selectedPdbId, setSelectedPdbId] = useState('');

    // Extract top_k and prob_threshold from Redux
    const initialDrugsParameters = useSelector((state) => state.parameters.predictInitialDrugs);
    const conv_ID = useSelector((state) => state.conv_ID);
    const UserData = useSelector((state) => state.UserDetails);
    const dockParameters = useSelector((state) => state.parameters.dock);

    // Backend URL (from environment variables)
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchParameters()); // Fetch parameters when the component mounts
    }, [dispatch]);

    const drugID = drugDetails?.DRUG;
    const proteinID = proteinDetails?.PROTEIN;

    useEffect(() => {
        if (!data) {
            setError('No data provided');
            return;
        }

        if (!drugID || !proteinID) {
            setError('Invalid drug or protein data');
            return;
        }

        const cacheKey = `dp_${drugID}_${proteinID}`;

        const fetchData = async () => {
            try {
                // Check if data is cached in IndexedDB
                const cachedData = await db.proteins.get(cacheKey);
                if (cachedData) {
                    console.log('Using cached data:', cachedData);
                    setTableData(cachedData.data);
                    return;
                }

                const topk = initialDrugsParameters.topK || 100; // Default to 100 if not available
                const probThreshold = initialDrugsParameters.probThreshold || 0.6; // Default to 0.6
                // Fetch the relations based on the protein
                const response = await axios.get(`${BACKEND_URL}/relations/${proteinID}`);
                if (response.data.found && drugDetails?.C_SMILES !== null) {
                    const payload = {
                        s_list: [drugDetails?.C_SMILES], // Drug SMILES as list
                        num_cores: 2, // Hardcoded to 2 for now
                        target: proteinDetails?.PROTEIN, // Protein ID
                        top_k: topk, // Extracted from Redux
                        prob_threshold: probThreshold, // Extracted from Redux
                    };
                    // console.log(payload);
                    try {
                        const predictResponse = await fetch(`${BACKEND_URL}/auth/api/predict-initial-drugs`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(payload),
                        });

                        if (!predictResponse.ok) {
                            const errorResponse = await predictResponse.json();
                            const errorMessage = errorResponse.error || `HTTP error! Status: ${predictResponse.status}`;
                            throw new Error(errorMessage);
                        }
                        const dataFromBackend = await predictResponse.json();
                        // console.log('Data received from backend:', dataFromBackend);
                        if (dataFromBackend.short_msg ==='Low threshold') {
                            setError(dataFromBackend.message)
                        }
                        setPredTempResult(dataFromBackend.predTempResult);
                        setPredAdmet_result(dataFromBackend.predAdmet_result);
                    } catch (error) {
                        console.error('Error during prediction:', error.message);
                    }
                } else {
                    setError(
                        <span>
                            Unable to predict interaction between the given target
                            <span className="pl-2 text-[#494949]"> {proteinID} </span>
                            and drug
                            <span className="pl-2 text-[#494949]"> {drugID} </span>
                        </span>
                    );
                    return;
                }
            } catch (error) {
                console.error('Error fetching data for predicting drugs:', error);
                setError('Error fetching data for predicting drugs');
            }
        };

        fetchData();
    }, [data, initialDrugsParameters, drugDetails, proteinDetails]);

    // Function to limit cache size
    const limitCacheSize = async (maxSize = 50) => {
        const count = await db.proteins.count();
        if (count > maxSize) {
            const itemsToDelete = await db.proteins.orderBy('timestamp').limit(count - maxSize).toArray();
            const keysToDelete = itemsToDelete.map(item => item.id);
            await db.proteins.bulkDelete(keysToDelete);
            console.log('Evicted old cache entries:', keysToDelete);
        }
    };

    useEffect(() => {
        if (!predTempResult || !predTempAdmet_result) return;

        const drugID = drugDetails?.DRUG;
        const proteinID = proteinDetails?.PROTEIN;

        if (!drugID || !proteinID) {
            setError('Invalid drug or protein data');
            return;
        }

        const cacheKey = `dp_${drugID}_${proteinID}`;

        const fetchData = async () => {
            try {
                const predictDrugsData = predTempResult.map((item) => ({
                    DRUG__ID: item.DRUG__ID,
                    C_SMILES: item.C_SMILES,
                    PRED: item.PRED
                }));

                // Create a mapping of C_SMILES to admet results
                const admetData = predictDrugsData.reduce((acc, drug, index) => {
                    acc[drug.C_SMILES] = predTempAdmet_result[index] || {};
                    return acc;
                }, {});

                // Map predictDrugsData directly to the required table data structure
                const drugsData = predictDrugsData.map((predictItem) => ({
                    id: drugDetails?.DRUG || 'N/A',
                    smiles: predictItem.C_SMILES,
                    name: drugDetails?.NAME || 'N/A',
                    cov: predictItem.PRED,
                    dock: 'N/A', // Set to 'N/A' or other default value if not available
                    admet: admetData[predictItem.C_SMILES] || {},
                    structure: 'View',
                }));

                console.log('Prepared tableData:', drugsData); // Debugging statement
                setTableData(drugsData);

                // Save to IndexedDB
                await db.proteins.put({
                    id: cacheKey,
                    name: `Predicted interaction between ${drugID} and ${proteinID}`,
                    data: drugsData,
                    timestamp: Date.now(),
                });

                console.log('Data cached successfully');

                // Evict old cache entries if necessary
                await limitCacheSize(50);
            } catch (error) {
                console.error('Error preparing data:', error);
            }
        };

        fetchData();
    }, [predTempResult, predTempAdmet_result, drugDetails, proteinDetails]);

    const handleDockSmiles = async (smiles, selectedPdbId) => {
        // Set dock status to 'Docking' for matching SMILES before making the API call
        const dockingTableData = tableData.map(item => {
            const match = smiles.includes(item.smiles);
            if (match) {
                return {
                    ...item,
                    dock: 'Docking' // Set dock to 'Docking' while processing
                };
            }
            return item;
        });

        // Update the table to reflect the 'Docking' status
        setTableData(dockingTableData);

        console.log("Docking with SMILES:", smiles);
        console.log("Docking with selected PDB ID:", selectedPdbId);
        console.log("PROTEIN ID:", proteinDetails.PROTEIN);

        const exhaustiveness = dockParameters.exhaustiveness || 8;
        const cpus = dockParameters.cpus || 2;

        // Construct the payload
        const Dock = {
            uniprot_acc: proteinDetails.PROTEIN,
            pdb_id: selectedPdbId,
            exhaustiveness,
            cpus,
            smiles_list: smiles
        };
        const payload = {
            email: UserData.email,
            conversationID: conv_ID,
            queryID: Query_ID,
            DockValues: Dock,
        };

        console.log(payload);

        try {
            const response = await fetch(`${BACKEND_URL}/auth/save-dock-result`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });
            const result = await response.json();
            // Handle the response from the server
            console.log(result);

            // Create an object to store the results for each SMILES
            const dockingData = smiles.map(eachsmile => {
                const filteredResults = result.results.filter(result => result.smiles === eachsmile);

                return {
                    smiles: eachsmile,
                    dockData: filteredResults.map(result => ({
                        model: result.model,
                        'binding affinity': result['binding affinity'],
                        'rmsd l.b.': result['rmsd l.b.'],
                        'rmsd u.b.': result['rmsd u.b.'],
                    }))
                };
            });

            // Output the docking data
            console.log(dockingData);

            if (result) {
                console.log('Molecule saved successfully');

                // Update tableData again to set dock to 'View' after docking completes
                const updatedTableData = dockingTableData.map(item => {
                    const matchData = dockingData.find(dock => dock.smiles === item.smiles);
                    if (matchData) {
                        return {
                            ...item,
                            dock: 'View', // Set dock to 'View' after successful docking
                            dockResult: matchData.dockData // Include the extra property from dockingData
                        };
                    }
                    return item;
                });

                // Update state with the new table data
                setTableData(updatedTableData);

                // Optionally, update the cache with the new docking results
                const cacheKey = `dp_${drugDetails?.DRUG}_${proteinDetails?.PROTEIN}`;
                await db.proteins.put({
                    id: cacheKey,
                    name: `Predicted interaction between ${drugDetails?.DRUG} and ${proteinDetails?.PROTEIN}`,
                    data: updatedTableData,
                    timestamp: Date.now(),
                });
                console.log('Cache updated with docking results');
            } else {
                console.error('Error saving molecule:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
        setDockSmiles(null);
    };

    const handleSaveSmiles = async (editedMoleculesArray = []) => {
        if (!Array.isArray(editedMoleculesArray) || editedMoleculesArray.length === 0) {
            console.error('No molecules were edited:', editedMoleculesArray);
            setEditSmiles(null);
            return;
        }

        // Prepare the molecule data to send to the backend
        const moleculeArray = editedMoleculesArray.map(({ initialSmiles, currentSmiles }) => {
            const editedMolecule = tableData.find((item) => item.smiles === initialSmiles);
            return {
                sourceMoleculeID: editedMolecule?.id || '',
                sourceMoleculeSMILES: initialSmiles,
                editedMoleculeSMILES: currentSmiles,
            };
        });

        // Update tableData for edited molecules
        const updatedTableData = tableData.map((item) => {
            const editedMolecule = editedMoleculesArray.find(
                (mol) => mol.initialSmiles === item.smiles
            );
            if (editedMolecule) {
                const molecule = OCL.Molecule.fromSmiles(editedMolecule.currentSmiles);
                const svg = molecule.toSVG(300, 300);
                return {
                    ...item,
                    smiles: editedMolecule.currentSmiles,
                    truncatedSmiles:
                        editedMolecule.currentSmiles.length > 10
                            ? `${editedMolecule.currentSmiles.substring(0, 10)}...`
                            : editedMolecule.currentSmiles,
                    editedMoleculeSvg: btoa(svg),
                };
            } else {
                return item;
            }
        });

        setTableData(updatedTableData);

        // Proceed with sending to the backend
        try {
            const payload = {
                email: UserData.email,
                conversationID: conv_ID,
                queryName: `Interaction between ${drugID} and ${proteinID}`,
                queryID: Query_ID,
                proteinID: proteinID,
                molecules: moleculeArray,
            };

            const response = await fetch(`${BACKEND_URL}/auth/save-edited-molecule`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            if (result.status) {
                console.log('Molecule saved successfully');
            } else {
                console.error('Error saving molecule:', result.message);
            }

            // Optionally, update the cache with the edited molecules
            await db.proteins.put({
                id: proteinID,
                name: `Interaction between ${drugID} and ${proteinID}`,
                data: updatedTableData,
                timestamp: Date.now(),
            });
            console.log('Cache updated with edited molecules');
        } catch (error) {
            console.error('Error:', error);
        }

        setEditSmiles(null);
    };

    return (
        <>
            <div className="p-4">
                {error ? (
                    <div className="text-xl font-bold mb-4 text-gray-400">{error}</div>
                ) : (
                    <>
                        <h2 className="text-xl font-bold mb-4 text-[#494949]">
                            Predicted interaction between {drugDetails?.DRUG} and {proteinDetails?.PROTEIN}
                        </h2>
                        {tableData.length > 0 ? (
                            <TableView
                                argument={data}
                                data={tableData}
                                selectable={true}
                                onAdmetClick={(admet, drugName) => {
                                    setSelectedAdmet(admet);
                                    setSelectedDrugName(drugName);
                                }}
                                onEditSmiles={(smiles) => setEditSmiles(smiles)}
                                onDockSmiles={(smiles) => setDockSmiles(smiles)}
                                selectedPdbId={selectedPdbId}
                            />
                        ) : (
                            <div className="text-xl font-bold mb-4 text-gray-300">Loading....</div>
                        )}
                    </>
                )}
            </div>
            {selectedAdmet && (
                <AdmetPopup
                    admet={selectedAdmet}
                    drugName={selectedDrugName}
                    onClose={() => setSelectedAdmet(null)}
                />
            )}

            {/* Rendering the MoleculeEditorPopup with batch editing functionality */}
            {editSmiles && (
                <MoleculeEditorPopup
                    smilesList={Array.isArray(editSmiles) ? editSmiles : [editSmiles]} // Ensure smilesList is an array
                    onSaveAll={handleSaveSmiles}
                    onClose={() => setEditSmiles(null)}
                />
            )}

            {dockSmiles && (
                <DockingPopup
                    proteinID={proteinDetails?.PROTEIN}
                    smilesList={Array.isArray(dockSmiles) ? dockSmiles : [dockSmiles]}
                    onDock={(smiles, selectedPdbId) => {
                        setSelectedPdbId(selectedPdbId);
                        handleDockSmiles(smiles, selectedPdbId);
                    }}
                    onClose={() => setDockSmiles(null)}
                />
            )}
        </>
    );
};

export default DrugProteinPair;