import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const UserAdminPage = () => {
    const [userData, setUserData] = useState([]);
    const [stats, setStats] = useState({
        totalConversations: 0,
        totalMoleculesEdited: 0,
        totalTokensUsed: 0,
        totalUsers: 0,
    });
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const allowedEmails = ['sai20401@iiitd.ac.in', 'chandrikak@iiitd.ac.in']; // Allowed email addresses

    const checkAuthorization = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/auth/verify`);
            const { email } = response.data.user;

            if (allowedEmails.includes(email)) {
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
                navigate('/unauthorized'); // Redirect to an unauthorized page or homepage
            }
        } catch (error) {
            console.error('Error checking authorization:', error);
            navigate('/login'); // Redirect to login if there's an error (e.g., not logged in)
        }
    };

    const fetchStats = async () => {
        try {
            // Fetch user data
            const userResponse = await axios.get(`${BACKEND_URL}/auth/usersList`);
            const users = userResponse.data;
    
            // Calculate the total number of edited molecules
            const totalMoleculesEdited = users.reduce((acc, user) => {
                return acc + (user.editedMolecules?.length || 0);
            }, 0);
    
            // Fetch conversation data
            const conversationResponse = await axios.get(`${BACKEND_URL}/conversations`);
            const conversations = conversationResponse.data;
    
            let totalTokensUsed = 0;
            let totalConversations = conversations.length;
    
            conversations.forEach(conversation => {
                const history = JSON.parse(conversation.History);
                if (history.type === 'ai' && history.data.response_metadata && history.data.response_metadata.token_usage) {
                    totalTokensUsed += history.data.response_metadata.token_usage.total_tokens || 0;
                }
            });
    
            setStats({
                totalConversations,
                totalMoleculesEdited,
                totalTokensUsed,
                totalUsers: users.length,
            });
    
            setUserData(users);
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    useEffect(() => {
        checkAuthorization(); // Check if the user is authorized
        if (isAuthorized) {
            fetchStats(); // Fetch both user and conversation data if authorized
        }
    }, [isAuthorized]);

    if (!isAuthorized) {
        return <p>Checking authorization...</p>; // Or show a loading spinner
    }

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">User Admin</h1>
            <div className="grid grid-cols-4 gap-4 mb-6">
                <div className="bg-gray-200 p-4 rounded-lg text-center">
                    <h2 className="text-3xl font-bold">{stats.totalConversations}</h2>
                    <p className="text-gray-600">Total Queries</p>
                </div>
                <div className="bg-gray-200 p-4 rounded-lg text-center">
                    <h2 className="text-3xl font-bold">{stats.totalMoleculesEdited}</h2>
                    <p className="text-gray-600">Total Molecules Edited</p>
                </div>
                <div className="bg-gray-200 p-4 rounded-lg text-center">
                    <h2 className="text-3xl font-bold">{stats.totalTokensUsed}</h2>
                    <p className="text-gray-600">Total Tokens Used</p>
                </div>
                <div className="bg-gray-200 p-4 rounded-lg text-center">
                    <h2 className="text-3xl font-bold">{stats.totalUsers}</h2>
                    <p className="text-gray-600">Total Users</p>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Index</th>
                            <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">User ID</th>
                            <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">User</th>
                            <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Status</th>
                            <th className="py-2 px-4 text-left text-sm font-medium text-gray-600">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData.map((user, index) => (
                            <tr key={user._id} className="border-b">
                                <td className="py-2 px-4 text-sm text-gray-700">{index + 1}</td>
                                <td className="py-2 px-4 text-sm text-gray-700">
                                    <Link to={`/user/${user._id}`}>
                                        {user._id.slice(0, 10)}...
                                    </Link>
                                </td>
                                <td className="py-2 px-4 text-sm text-gray-700">{user.username}</td>
                                <td className="py-2 px-4 text-sm text-gray-700">{user.isConfirmed ? 'Active' : 'Inactive'}</td>
                                <td className="py-2 px-4 text-sm text-gray-700">
                                    <select className="border-gray-300 rounded px-2 py-1">
                                        <option value="actions">Actions</option>
                                        <option value="delete">Delete</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserAdminPage;