import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { fetchDiseases, fetchDrugs, fetchProteins, userDetails } from '../../redux/Actions/action';
import { useDispatch, useSelector } from 'react-redux';
import { PiArrowSquareRightLight, PiArrowSquareRightFill } from "react-icons/pi";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import formatAMPM from '../Functions/FormatAMPM';
import { useNavigate } from 'react-router-dom';

const options = ['drug', 'disease', 'protein'];

const SearchInput = forwardRef((props, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showOptionMatch, setShowOptionMatch] = useState(false);
    const [searchItem, setSearchItem] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const [filterDiseases, setFilterDiseases] = useState([]);
    const [filteredDiseasesByDisease, setFilteredDiseasesByDisease] = useState([]);
    const [filteredDiseasesByName, setFilteredDiseasesByName] = useState([]);

    const [filterProteins, setFilterProteins] = useState([]);
    const [filteredProteinsByProteins, setFilteredProteinsByProteins] = useState([]);
    const [filteredProteinsByName, setFilteredProteinsByName] = useState([]);
    const [filteredProteinsByFullName, setFilteredProteinsByFullName] = useState([]);

    const [filterDrugs, setFilterDrugs] = useState([]);
    const [filteredDrugsByID, setFilteredDrugsByID] = useState([]);
    const [filteredDrugsByName, setFilteredDrugsByName] = useState([]);
    const [filteredDrugsByProduct, setFilteredDrugsByProduct] = useState([]);
    const [filteredDrugsBySynonym, setFilteredDrugsBySynonym] = useState([]);
    const [filteredDrugsByATCCode, setFilteredDrugsByATCCode] = useState([]);
    const [filteredDrugsByDrugBankName, setFilteredDrugsByDrugBankName] = useState([]);
    const [filteredDrugsByCSmiles, setFilteredDrugsByCSmiles] = useState([]);
    const [filteredDrugsByDrugInchKE, setFilteredDrugsByDrugInchKE] = useState([]);
    const [filteredDrugsByDrugInKe, setFilteredDrugsByDrugInKe] = useState([]);

    const [DRUGARRAY, setDrugsArray] = useState([]);
    const [PROTEINSARRAY, setProteinsArray] = useState([]);
    const [DISESESARRAY, setDiseasesArray] = useState([]);

    const navigate = useNavigate();

    const [selectedListItem, setSelectedListItem] = useState(null);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const conv_ID = useSelector((state) => state.conv_ID);

    // const DiseasesData = useSelector((state) => state.Diseases);
    // const ProteinsData = useSelector((state) => state.Proteins);
    // const DrugsData = useSelector((state) => state.Drugs);
    const UserData = useSelector((state) => state.UserDetails);
    const [DrugsData, setDrugsData] = useState([]);
    const [ProteinsData, setProteinsData] = useState([]);
    const [DiseasesData, setDiseasesData] = useState([]);
    const [error, setError] = useState('');
    // const [conv_ID, setConv_ID] = useState(null);
    const [loading, setLoading] = useState(false);

    const [drugOptions, setDrugOptions] = useState([]);
    const [diseaseOptions, setDiseaseOptions] = useState([]);
    const [proteinOptions, setProteinOptions] = useState([]);

    useEffect(() => {
        if (selectedOption === 'disease' && searchItem.length > 0) {
            let options = [];
            let index = 0;

            // Filter by Disease ID
            if (filteredDiseasesByDisease.length > 0) {
                filteredDiseasesByDisease.slice(0, 5).forEach((disease) => {
                    options.push({
                        disease,
                        type: 'disease',
                        displayText: disease.DISEASE,
                        section: 'ID\'s',
                        index: index++,
                    });
                });
            }

            // Filter by Name
            if (filteredDiseasesByName.length > 0) {
                filteredDiseasesByName.slice(0, 5).forEach((disease) => {
                    const displayText = Array.isArray(disease.NAME) ? disease.NAME.join(', ') : disease.NAME;
                    options.push({
                        disease,
                        type: 'name',
                        displayText,
                        section: 'Names',
                        index: index++,
                    });
                });
            }
            setDiseaseOptions(options);
        } else {
            setDiseaseOptions([]);
        }
    }, [
        selectedOption,
        searchItem,
        filteredDiseasesByDisease,
        filteredDiseasesByName,
    ]);

    useEffect(() => {
        if (selectedOption === 'protein' && searchItem.length > 0) {
            let options = [];
            let index = 0;

            // Filter by Protein ID
            if (filteredProteinsByProteins.length > 0) {
                filteredProteinsByProteins.slice(0, 5).forEach((protein) => {
                    options.push({
                        protein,
                        type: 'type',
                        displayText: protein.PROTEIN,
                        section: 'ID\'s',
                        index: index++,
                    });
                });
            }

            // Filter by Name
            if (filteredProteinsByName.length > 0) {
                filteredProteinsByName.slice(0, 5).forEach((protein) => {
                    options.push({
                        protein,
                        type: 'name',
                        displayText: protein.NAME,
                        section: 'Names',
                        index: index++,
                    });
                });
            }

            // Filter by Full Name
            if (filteredProteinsByFullName.length > 0) {
                filteredProteinsByFullName.slice(0, 5).forEach((protein) => {
                    const displayText = Array.isArray(protein.FULL_NAME) ? protein.FULL_NAME.join(', ') : protein.FULL_NAME;
                    options.push({
                        protein,
                        type: 'fullname',
                        displayText,
                        section: 'Full Names',
                        index: index++,
                    });
                });
            }

            setProteinOptions(options);
        } else {
            setProteinOptions([]);
        }
    }, [
        selectedOption,
        searchItem,
        filteredProteinsByProteins,
        filteredProteinsByName,
        filteredProteinsByFullName,
    ]);

    useEffect(() => {
        if (selectedOption === 'drug' && searchItem.length > 0) {
            let options = [];
            let index = 0;

            // Filter by ID
            if (filteredDrugsByID.length > 0) {
                filteredDrugsByID.slice(0, 5).forEach((drug) => {
                    options.push({
                        drug,
                        type: 'drug',
                        displayText: drug.DRUG,
                        section: 'ID\'s',
                        index: index++,
                    });
                });
            }

            // Filter by Name
            if (filteredDrugsByName.length > 0) {
                filteredDrugsByName.slice(0, 5).forEach((drug) => {
                    options.push({
                        drug,
                        type: 'name',
                        displayText: drug.NAME,
                        section: 'Names',
                        index: index++,
                    });
                });
            }

            // Filter by Product
            if (filteredDrugsByProduct.length > 0) {
                filteredDrugsByProduct.slice(0, 5).forEach((drug) => {
                    const displayText = Array.isArray(drug.PRODUCT) ? drug.PRODUCT.join(', ') : drug.PRODUCT;
                    options.push({
                        drug,
                        type: 'product',
                        displayText,
                        section: 'Products',
                        index: index++,
                    });
                });
            }

            // Filter by Synonym
            if (filteredDrugsBySynonym.length > 0) {
                filteredDrugsBySynonym.slice(0, 5).forEach((drug) => {
                    const displayText = Array.isArray(drug.SYNONYM) ? drug.SYNONYM.join(', ') : drug.SYNONYM;
                    options.push({
                        drug,
                        type: 'synonym',
                        displayText,
                        section: 'Synonyms',
                        index: index++,
                    });
                });
            }

            // Filter by ATC Code
            if (filteredDrugsByATCCode.length > 0) {
                filteredDrugsByATCCode.slice(0, 5).forEach((drug) => {
                    options.push({
                        drug,
                        type: 'ATC',
                        displayText: drug.DRUG_ATC_CODE,
                        section: 'ATC Codes',
                        index: index++,
                    });
                });
            }

            // Filter by DrugBank Name
            if (filteredDrugsByDrugBankName.length > 0) {
                filteredDrugsByDrugBankName.slice(0, 5).forEach((drug) => {
                    options.push({
                        drug,
                        type: 'DrugBankName',
                        displayText: drug.DRUGBANK_NAME,
                        section: 'DrugBank Names',
                        index: index++,
                    });
                });
            }

            // Filter by C Smiles
            if (filteredDrugsByCSmiles.length > 0) {
                filteredDrugsByCSmiles.slice(0, 5).forEach((drug) => {
                    options.push({
                        drug,
                        type: 'csmiles',
                        displayText: drug.C_SMILES,
                        section: 'C Smiles',
                        index: index++,
                    });
                });
            }

            // Filter by DrugInchKE
            if (filteredDrugsByDrugInchKE.length > 0) {
                filteredDrugsByDrugInchKE.slice(0, 5).forEach((drug) => {
                    options.push({
                        drug,
                        type: 'DrugInchKE',
                        displayText: drug.DRUGINCHKE,
                        section: 'DrugInchKE',
                        index: index++,
                    });
                });
            }

            // Filter by DrugInKe
            if (filteredDrugsByDrugInKe.length > 0) {
                filteredDrugsByDrugInKe.slice(0, 5).forEach((drug) => {
                    options.push({
                        drug,
                        type: 'DrugInKe',
                        displayText: drug.DRUGINKE,
                        section: 'DrugInKe',
                        index: index++,
                    });
                });
            }

            setDrugOptions(options);
        } else {
            setDrugOptions([]);
        }
    }, [
        selectedOption,
        searchItem,
        filteredDrugsByID,
        filteredDrugsByName,
        filteredDrugsByProduct,
        filteredDrugsBySynonym,
        filteredDrugsByATCCode,
        filteredDrugsByDrugBankName,
        filteredDrugsByCSmiles,
        filteredDrugsByDrugInchKE,
        filteredDrugsByDrugInKe
    ]);

    const handleSearch = async (value) => {
        if (value.length >= 1) {
            setLoading(true);
            setError('');

            try {
                if (selectedOption === 'drug') {
                    const response = await axios.get(`${BACKEND_URL}/${selectedOption}/search?q=${value}&limit=10`);
                    // console.log('Fetched Drugs:', response.data);
                    setDrugsData(response.data);
                } else if (selectedOption === 'protein') {
                    const response = await axios.get(`${BACKEND_URL}/${selectedOption}/search?q=${value}&limit=10`);
                    setProteinsData(response.data);
                } else if (selectedOption === 'disease') {
                    const response = await axios.get(`${BACKEND_URL}/${selectedOption}/search?q=${value}&limit=10`);
                    setDiseasesData(response.data);
                }
            } catch (err) {
                setError('Error fetching results');
            } finally {
                setLoading(false);
            }
        } else {
            setDrugsData([]);
            setProteinsData([]);
            setDiseasesData([]);
        }
    };
    useEffect(() => {
        const handler = setTimeout(() => {
            handleSearch(searchItem);
        }, 300); // Debounce by 300ms

        return () => {
            clearTimeout(handler);
        };
    }, [searchItem]);

    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);
    const optionMatchRef = useRef(null);

    useEffect(() => {
        // dispatch(fetchDiseases());
        // dispatch(fetchProteins());
        // dispatch(fetchDrugs());
        dispatch(userDetails());
    }, [dispatch]);

    useEffect(() => {
        const filteredDiseasesByDisease = DiseasesData.filter((eachDisease) =>
            eachDisease.DISEASE.toLowerCase().includes(searchItem.toLowerCase())
        );
        setFilteredDiseasesByDisease(filteredDiseasesByDisease);

        const filteredDiseasesByName = DiseasesData.filter((eachDisease) =>
            // eachDisease.NAME && eachDisease.NAME.toLowerCase().includes(searchItem.toLowerCase().trim())
            Array.isArray(eachDisease.NAME)
                ? eachDisease.NAME.some(fullNameItem => fullNameItem.toLowerCase().includes(searchItem))
                : eachDisease.NAME && eachDisease.NAME.toLowerCase().includes(searchItem)
        );
        setFilteredDiseasesByName(filteredDiseasesByName);
        const filteredDiseases = [...filteredDiseasesByDisease, ...filteredDiseasesByName];

        setFilterDiseases(filteredDiseases);
    }, [DiseasesData, searchItem]);

    useEffect(() => {
        const filteredProteinsByProteins = ProteinsData.filter((eachProteins) =>
            eachProteins.PROTEIN.toLowerCase().includes(searchItem.toLowerCase())
        );
        setFilteredProteinsByProteins(filteredProteinsByProteins);

        const filteredProteinsByName = ProteinsData.filter((eachProteins) =>
            eachProteins.NAME && eachProteins.NAME.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredProteinsByName(filteredProteinsByName);

        const filteredProteinsByFullName = ProteinsData.filter((eachProtein) =>
            Array.isArray(eachProtein.FULL_NAME)
                ? eachProtein.FULL_NAME.some(fullNameItem => fullNameItem.toLowerCase().includes(searchItem))
                : eachProtein.FULL_NAME && eachProtein.FULL_NAME.toLowerCase().includes(searchItem)
        );
        setFilteredProteinsByFullName(filteredProteinsByFullName);

        const filteredProteins = [...filteredProteinsByProteins, ...filteredProteinsByName, ...filteredProteinsByFullName];

        setFilterProteins(filteredProteins);

    }, [ProteinsData, searchItem]);

    useEffect(() => {
        const filteredDrugsByID = DrugsData.filter((drug) =>
            drug.DRUG.toLowerCase().includes(searchItem.toLowerCase())
        );
        setFilteredDrugsByID(filteredDrugsByID);

        const filteredDrugsByName = DrugsData.filter((drug) =>
            drug.NAME && drug.NAME.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredDrugsByName(filteredDrugsByName);
        const filteredDrugsByProduct = DrugsData.filter((drug) =>
            Array.isArray(drug.PRODUCT)
                ? drug.PRODUCT.some(item => item.toLowerCase().includes(searchItem.toLowerCase().trim()))
                : drug.PRODUCT && drug.PRODUCT.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredDrugsByProduct(filteredDrugsByProduct);
        const filteredDrugsBySynonym = DrugsData.filter((drug) =>
            Array.isArray(drug.SYNONYM)
                ? drug.SYNONYM.some(synonym => synonym.toLowerCase().includes(searchItem.toLowerCase().trim()))
                : drug.SYNONYM && drug.SYNONYM.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredDrugsBySynonym(filteredDrugsBySynonym);

        const filteredDrugsByATCCode = DrugsData.filter((drug) =>
            drug.DRUG_ATC_CODE && drug.DRUG_ATC_CODE.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredDrugsByATCCode(filteredDrugsByATCCode);

        const filteredDrugsByDrugBankName = DrugsData.filter((drug) =>
            drug.DRUGBANK_NAME && drug.DRUGBANK_NAME.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredDrugsByDrugBankName(filteredDrugsByDrugBankName);

        const filteredDrugsByCSmiles = DrugsData.filter((drug) =>
            drug.C_SMILES && drug.C_SMILES.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredDrugsByCSmiles(filteredDrugsByCSmiles);

        const filteredDrugsByDrugInchKE = DrugsData.filter((drug) =>
            drug.DRUGINCHKE && drug.DRUGINCHKE.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredDrugsByDrugInchKE(filteredDrugsByDrugInchKE);

        const filteredDrugsByDrugInKe = DrugsData.filter((drug) =>
            drug.DRUGINKE && drug.DRUGINKE.toLowerCase().includes(searchItem.toLowerCase().trim())
        );
        setFilteredDrugsByDrugInKe(filteredDrugsByDrugInKe);

        const filteredDrugs = [
            ...filteredDrugsByID,
            ...filteredDrugsByName,
            ...filteredDrugsByProduct,
            ...filteredDrugsBySynonym,
            ...filteredDrugsByATCCode,
            ...filteredDrugsByDrugBankName,
            ...filteredDrugsByCSmiles,
            ...filteredDrugsByDrugInchKE,
            ...filteredDrugsByDrugInKe,
        ];
        setFilterDrugs(filteredDrugs);
    }, [DrugsData, searchItem]);

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        const atIndex = value.lastIndexOf('@');
        if (atIndex !== -1) {
            const query = value.slice(atIndex + 1);
            const optionMatch = options.find(option => query.startsWith(option));
            if (optionMatch) {
                setSelectedOption(optionMatch);
                setShowSuggestions(false);
                if (optionMatch === 'disease' || optionMatch === 'protein' || optionMatch === 'drug') {
                    const searchQuery = query.replace(optionMatch, '').trim();
                    setShowOptionMatch(searchQuery.length > 0);
                    setSearchItem(searchQuery);
                } else {
                    setShowOptionMatch(false);
                }
            } else {
                setSelectedOption(null);
                setShowSuggestions(true);
                setShowOptionMatch(false);
            }
        } else {
            setShowSuggestions(false);
            setShowOptionMatch(false);
        }
        setHighlightedIndex(-1); // Reset highlighted index
    };

    const handleSuggestionClick = (suggestion) => {
        const atIndex = inputValue.lastIndexOf('@');
        const value = inputValue.slice(0, atIndex + 1) + `${suggestion} `;
        setInputValue(value);
        setSelectedOption(suggestion);
        setShowSuggestions(false);
        if (suggestion === 'disease' || suggestion === 'protein' || suggestion === 'drug') {
            setShowOptionMatch(true);
            setSearchItem('');
        } else {
            setShowOptionMatch(false);
        }

        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(value.length, value.length);
            }, 0);
        }

        setHighlightedIndex(-1);
    };

    const handleDiseaseClick = (disease, type) => {
        let selectedItem = disease;
        setSelectedListItem(selectedItem);

        const atIndex = inputValue.lastIndexOf('@disease');
        const baseValue = inputValue.slice(0, atIndex + '@disease'.length + 1);

        let value;
        if (type === 'disease') {
            value = `${baseValue}${disease.DISEASE} `;
        } else if (type === 'name') {
            if (Array.isArray(disease.NAME)) {
                // Filter the NAME array based on the searchTerm
                const filteredName = disease.NAME.find(name =>
                    name.toLowerCase().includes(searchItem.toLowerCase())
                );
                if (filteredName) {
                    value = `${baseValue}${filteredName} `;
                } else {
                    value = `${baseValue}${disease.NAME.join(', ')} `;
                }
            } else {
                value = `${baseValue}${disease.NAME} `;
            }
        }

        setInputValue(value);
        setShowOptionMatch(false);

        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(value.length, value.length);
            }, 0);
        }

        setHighlightedIndex(-1);
        // Push selectedItem to DISESESARRAY
        setDiseasesArray(prevArray => [...prevArray, selectedItem]);
        console.log("Updated DISESESARRAY:", [...DISESESARRAY, selectedItem]);
    };

    const handleProteinClick = (protein, type) => {
        let selectedItem = protein;
        setSelectedListItem(selectedItem);

        const atIndex = inputValue.lastIndexOf('@protein');
        const baseValue = inputValue.slice(0, atIndex + '@protein'.length + 1);

        let value;
        if (type === 'type') {
            value = `${baseValue}${protein.PROTEIN} `;
        } else if (type === 'name') {
            value = `${baseValue}${protein.NAME} `;
        } else if (type === 'fullname') {
            if (Array.isArray(protein.FULL_NAME)) {
                // Filter the FULL_NAME array based on the searchTerm
                const filteredFullName = protein.FULL_NAME.find(name =>
                    name.toLowerCase().includes(searchItem.toLowerCase())
                );
                if (filteredFullName) {
                    value = `${baseValue}${filteredFullName} `;
                } else {
                    value = `${baseValue}${protein.FULL_NAME.join(', ')} `;
                }
            } else {
                value = `${baseValue}${protein.FULL_NAME} `;
            }
        } else {
            return;
        }

        setInputValue(value);
        setShowOptionMatch(false);

        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(value.length, value.length);
            }, 0);
        }

        setHighlightedIndex(-1);
        // Push selectedItem to DISESESARRAY
        setProteinsArray(prevArray => [...prevArray, selectedItem]);
        console.log("Updated PROTEINSARRAY:", [...PROTEINSARRAY, selectedItem]);
    };

    const handleDrugClick = (drug, type) => {
        let selectedItem = drug;
        setSelectedListItem(selectedItem);

        const atIndex = inputValue.lastIndexOf(`@${selectedOption}`);
        const baseValue = inputValue.slice(0, atIndex + `@${selectedOption}`.length + 1);

        let value;
        if (type === 'drug') {
            value = `${baseValue}${drug.DRUG} `;
        } else if (type === 'name') {
            value = `${baseValue}${drug.NAME} `;
        } else if (type === 'product') {
            if (searchItem && searchItem.trim() !== '') {
                if (Array.isArray(drug.PRODUCT)) {
                    // Filter the PRODUCT array based on the searchItem
                    const filteredProduct = drug.PRODUCT.find(product =>
                        product.toLowerCase().includes(searchItem.toLowerCase().trim())
                    );
                    if (filteredProduct) {
                        value = `${baseValue}${filteredProduct.trim()} `;
                    } else {
                        value = `${baseValue}${drug.PRODUCT.map(p => p.trim()).join(', ')} `;
                    }
                } else {
                    value = `${baseValue}${drug.PRODUCT.trim()} `;
                }
            } else {
                value = `${baseValue}${Array.isArray(drug.PRODUCT) ? drug.PRODUCT.map(p => p.trim()).join(', ') : drug.PRODUCT.trim()} `;
            }

        } else if (type === 'synonym') {
            if (Array.isArray(drug.SYNONYM)) {
                // Filter the SYNONYM array based on the searchTerm
                const filteredSynonym = drug.SYNONYM.find(synonym =>
                    synonym.toLowerCase().includes(searchItem.toLowerCase())
                );
                if (filteredSynonym) {
                    value = `${baseValue}${filteredSynonym} `;
                } else {
                    value = `${baseValue}${drug.SYNONYM.join(', ')} `;
                }
            } else {
                value = `${baseValue}${drug.SYNONYM} `;
            }
        } else if (type === 'ATC') {
            value = `${baseValue}${drug.DRUG_ATC_CODE} `;
        } else if (type === 'DrugBankName') {
            value = `${baseValue}${drug.DRUGBANK_NAME} `;
        } else if (type === 'csmiles') {
            value = `${baseValue}${drug.C_SMILES} `;
        } else if (type === 'DrugInchKE') {
            value = `${baseValue}${drug.DRUGINCHKE} `;
        } else if (type === 'DrugInKe') {
            value = `${baseValue}${drug.DRUGINKE} `;
        }

        setInputValue(value);
        setShowOptionMatch(false);

        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(value.length, value.length);
            }, 0);
        }
        setHighlightedIndex(-1);
        // Push selectedItem to DISESESARRAY
        setDrugsArray(prevArray => [...prevArray, selectedItem]);
        console.log("Updated DRUGSARRAY:", [...DRUGARRAY, selectedItem]);
    };
    // useEffect(() => {
    //     // Check if conv_ID is already stored in localStorage or create a new one
    //     const storedConv_ID = localStorage.getItem('conv_ID');
    //     if (storedConv_ID) {
    //         setConv_ID(storedConv_ID);
    //     } else {
    //         const newConv_ID = uuidv4().replace(/-/g, '').trim();
    //         localStorage.setItem('conv_ID', newConv_ID);
    //         setConv_ID(newConv_ID);
    //     }
    // }, []);

    const handleIconClick = async () => {
        if (inputValue) {
            setLoading(true); // Start loading when the icon is clicked
            console.log('Enter button clicked, Input Value:', inputValue);
            const generateID = () => Math.random().toString(36).substr(2, 9);
            // const generateConv_ID = () => uuidv4().replace(/-/g, '').trim();
            // const Conv_ID = generateConv_ID();

            const dateTime = new Date();
            const formattedTime = formatAMPM(dateTime);
            let combinedDetails = { Proteins: [], Diseases: [], Drugs: [] };
            // let combinedDetails = { Proteins: PROTEINSARRAY, Diseases: DISESESARRAY, Drugs: DRUGARRAY };
            let modifiedQuery = inputValue;
            // Check if the input matches the specific condition (Home Page)
            if (inputValue === "Predict drugs for the given @protein SFRP4") {
                combinedDetails = {
                    Proteins: [
                        {
                            ID: "Q6FHJ7",
                            NAME: "SFRP4",
                            FULL_NAME: [
                                "Secreted frizzled-related protein 4",
                                "Frizzled protein",
                                "human endometrium"
                            ]
                        }
                    ],
                    Diseases: [],
                    Drugs: []
                };
                modifiedQuery = "predict drugs for the given protein Q6FHJ7"
                // console.log('Condition matched, combinedDetails set to:', combinedDetails);
            } else if (inputValue === "Get details for @protein Norepinephrine transporter") {
                combinedDetails = {
                    "Proteins": [

                        {
                            "ID": "P23975",
                            "NAME": "SC6A2",
                            "FULL_NAME": [
                                "Sodium-dependent noradrenaline transporter",
                                " Norepinephrine transporter",
                                " Solute carrier family 6 member 2"
                            ]
                        }
                    ],
                    "Diseases": [],
                    "Drugs": []
                };

                modifiedQuery = "Get details for protein P23975"
            } else if (inputValue === "Fetch me details about this @drug Desmopressin") {
                combinedDetails = {
                    "Proteins": [],
                    "Diseases": [],
                    "Drugs": [
                        {
                            "ID": "DB00035",
                            "NAME": "Desmopressin",
                            "DRUGBANK_NAME": "Desmopressin",
                        }
                    ]
                }
                modifiedQuery = "Fetch me details about this drug DB00035 "
            } else if (inputValue === "can you predict if @drug DB00477 will interact with @protein P21917") {
                combinedDetails = {
                    "Proteins": [
                        {
                            "ID": "P21917",
                            "NAME": "DRD4",
                            "FULL_NAME": [
                                "D(4) dopamine receptor",
                                " D(2C) dopamine receptor",
                                " Dopamine D4 receptor"
                            ]
                        }
                    ],
                    "Diseases": [],
                    "Drugs": [
                        {
                            "ID": "DB00477",
                            "NAME": "Chlorpromazine",
                        }
                    ]
                }
                modifiedQuery = "can you predict if drug DB00477 will interact with protein P21917"
            } else {

                // Find all matching proteins, diseases, and drugs details
                PROTEINSARRAY.forEach(protein => {
                    if (
                        inputValue.includes(protein.PROTEIN) ||
                        inputValue.includes(protein.NAME) ||
                        (protein.FULL_NAME &&
                            (Array.isArray(protein.FULL_NAME) ? protein.FULL_NAME.some(name => inputValue.includes(name)) : inputValue.includes(protein.FULL_NAME)))
                    ) {
                        combinedDetails.Proteins.push({ ID: protein.PROTEIN, NAME: protein.NAME, FULL_NAME: protein.FULL_NAME });
                    }
                });

                DISESESARRAY.forEach(disease => {
                    if (
                        inputValue.includes(disease.DISEASE) ||
                        inputValue.includes(disease.NAME) ||
                        (disease.NAME &&
                            (Array.isArray(disease.NAME) ? disease.NAME.some(name => inputValue.includes(name)) : inputValue.includes(disease.NAME)))
                    ) {
                        combinedDetails.Diseases.push({
                            id: disease.DISEASE,
                            name: disease.NAME,
                        });
                    }
                });

                DRUGARRAY.forEach(drug => {
                    if (
                        inputValue.includes(drug.DRUG) ||
                        inputValue.includes(drug.NAME) ||
                        inputValue.includes(drug.DRUG_ATC_CODE) ||
                        inputValue.includes(drug.DRUGBANK_NAME) ||
                        inputValue.includes(drug.C_SMILES) ||
                        inputValue.includes(drug.DRUGINCHKE) ||
                        inputValue.includes(drug.DRUGINKE) ||
                        (drug.SYNONYM &&
                            (Array.isArray(drug.SYNONYM) ? drug.SYNONYM.some(synonym => inputValue.includes(synonym)) : inputValue.includes(drug.SYNONYM))) ||
                        (drug.PRODUCT &&
                            (Array.isArray(drug.PRODUCT) ? drug.PRODUCT.some(product => inputValue.includes(product.trim())) : inputValue.includes(drug.PRODUCT.trim())))
                    ) {
                        combinedDetails.Drugs.push({
                            ID: drug.DRUG,
                            NAME: drug.NAME,
                            DRUGBANK_NAME: drug.DRUGBANK_NAME,
                            DRUG_ATC_CODE: drug.DRUG_ATC_CODE,
                            SYNONYM: drug.SYNONYM,
                            PRODUCT: drug.PRODUCT,
                            C_SMILES: drug.C_SMILES,
                            DRUGINCHKE: drug.DRUGINCHKE,
                            DRUGINKE: drug.DRUGINKE
                        });
                    }
                });

                // Modify simpleQuery to replace markers with IDs from combinedDetails

                // Check if the inputValue includes a specific marker and replace appropriately
                if (inputValue.includes('@drug')) {
                    combinedDetails.Drugs.forEach(drug => {
                        const fieldsToCheck = [drug.ID, drug.NAME, drug.DRUGBANK_NAME, drug.C_SMILES, drug.DRUGINCHKE, drug.DRUGINKE, drug.DRUG_ATC_CODE, ...drug.SYNONYM || [], ...drug.PRODUCT || []];
                        fieldsToCheck.forEach(field => {
                            if (inputValue.includes(field)) {
                                modifiedQuery = modifiedQuery.replace(new RegExp(`@drug\\s*(${field.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')})`, 'i'), `drug ${drug.ID}`);
                            }
                        });
                    });
                }
                if (inputValue.includes('@protein')) {
                    combinedDetails.Proteins.forEach(protein => {
                        // Normalize FULL_NAME to an array if it's not already
                        const fullNameArray = Array.isArray(protein.FULL_NAME) ? protein.FULL_NAME : [protein.FULL_NAME];
                        for (const field of [protein.NAME, protein.ID, ...fullNameArray]) {
                            // Ensure the field is a string before using it
                            if (typeof field === 'string') {
                                if (inputValue.includes(field.trim())) {
                                    // Construct the regex to match the @protein command
                                    const regex = new RegExp(
                                        `@protein\\s*${field.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}.*?`,
                                        'i'
                                    );

                                    // Replace with the protein ID and retain the remaining text
                                    modifiedQuery = modifiedQuery.replace(regex, `protein ${protein.ID}`);
                                }
                            }
                        }
                    });
                }

                if (inputValue.includes('@disease')) {
                    combinedDetails.Diseases.forEach(disease => {
                        const fieldsToCheck = [disease.id, ...(disease.name || [])];
                        fieldsToCheck.forEach(field => {
                            if (inputValue.includes(field)) {
                                modifiedQuery = modifiedQuery.replace(new RegExp(`@disease\\s*${field.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}.*?`, 'i'),
                                    `disease ${disease.id}`);
                            }
                        });
                    });
                }

                console.log('Modified Query:', modifiedQuery);
                // const simpleQuery = inputValue.replace(/@/g, '').trim();
            }
            // Construct JSON object for the query
            const requestjson = {
                username: UserData.username,
                conv_ID: conv_ID,
                Query_ID: generateID(),
                // QueryName: simpleQuery,
                QueryName: inputValue,
                SimpleQuery: modifiedQuery,
                Time: formattedTime,
                Entries: combinedDetails
            };

            setInputValue('');
            console.log('Requested LLM for response:', requestjson);

            // Fetch the existing conversation from MongoDB
            const fetchConversationResponse = await fetch(`${BACKEND_URL}/auth/get-conversation/${conv_ID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            let conversations;
            if (fetchConversationResponse.ok) {
                const existingConversation = await fetchConversationResponse.json();
                if (existingConversation && existingConversation.conversation) {
                    conversations = existingConversation.conversation;
                    console.log('Fetched existing conversation from MongoDB:', conversations);
                } else {
                    console.log('No existing conversation found, creating a new one');
                    conversations = {
                        conversationID: conv_ID,
                        queries: [],
                        Summary: []
                    };
                }
            } else {
                console.log('Error fetching conversation, creating a new one');
                conversations = {
                    conversationID: conv_ID,
                    queries: [],
                    Summary: []
                };
            }
            // Add the new query to the queries array
            conversations.queries.push(requestjson);
            // Save the requestjson to conversations
            console.log('Saving requestjson to MongoDB:', conversations);
            const saveResponse = await fetch(`${BACKEND_URL}/auth/save-conversation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ conversations }),
                credentials: 'include',
            });

            const saveData = await saveResponse.json();
            console.log('Document inserted to MongoDB:', saveData);

            navigate(`/c/${conv_ID}`);

            try {
                // Send request and Fetch data from server(LLM) OpenAI
                const responseFromOpenAI = await fetch(`${BACKEND_URL}/auth/call-openai`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestjson),
                });
                const dataFromOpenAI = await responseFromOpenAI.json();
                console.log('Response from server(LLM) OpenAI:', dataFromOpenAI);

                // Update the requestjson with the response data
                const updatedQuery = {
                    ...requestjson,
                    ToolCallResponse: dataFromOpenAI.ToolCallResponse || 'Error/undefined',
                    response: dataFromOpenAI.response || 'Error'
                };

                // Find the index of the saved requestjson in conversations
                const queryIndex = conversations.queries.findIndex(query => query.Query_ID === requestjson.Query_ID);
                if (queryIndex !== -1) {
                    // Update the query with the response data
                    conversations.queries[queryIndex] = updatedQuery;
                }

                console.log('Updated conversations with response:', conversations);

                // Save the updated conversation to MongoDB
                const updateResponse = await fetch(`${BACKEND_URL}/auth/save-conversation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ conversations }),
                    credentials: 'include',
                });

                const updateData = await updateResponse.json();
                console.log('Document updated in MongoDB:', updateData);
                // Stop loading after the save operation completes
                setLoading(false);

                // Request summary for every 5th query
                if (conversations.queries.length % 5 === 0) {
                    const summaryRequest = {
                        user_id: UserData,
                        conv_id: conv_ID,
                        // Query_id: queryForSummary.Query_ID
                    };

                    try {
                        console.log(`Requesting summary for conversation`);
                        const summaryResponse = await fetch(`${BACKEND_URL}/auth/call-openai-summary`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(summaryRequest),
                        });
                        const summaryData = await summaryResponse.json();
                        console.log('Summary response:', summaryData);
                        // Find the existing summary index or determine if we need to add a new summary
                        const summaryIndex = conversations.Summary.findIndex(summary => summary.someUniqueField === summaryData.someUniqueField);

                        if (summaryIndex !== -1) {
                            // Update the existing summary
                            conversations.Summary[summaryIndex] = summaryData;
                        } else {
                            // Add new summary if it doesn't exist
                            conversations.Summary.push(summaryData);
                        }

                        // Save the updated conversation with summaries to MongoDB
                        const summaryUpdateResponse = await fetch(`${BACKEND_URL}/auth/save-conversation`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ conversations }),
                            credentials: 'include',
                        });

                        const summaryUpdateData = await summaryUpdateResponse.json();
                        console.log('Document updated with summary in MongoDB:', summaryUpdateData);
                    } catch (error) {
                        console.error('Error requesting or saving summary:', error);
                    }
                }

            } catch (error) {
                console.error('Error processing the request:', error);
                setLoading(false);
            }
            setSelectedListItem(null);
            setSearchItem('');
        }
        console.log(conv_ID)
        setDiseasesArray([])
        setDrugsArray([])
        setProteinsArray([])
    };

    useEffect(() => {
        if (showSuggestions) {
            const atIndex = inputValue.lastIndexOf('@');
            if (atIndex !== -1) {
                const query = inputValue.slice(atIndex + 1).toLowerCase();
                const filteredSuggestions = options.filter(option =>
                    option.toLowerCase().includes(query)
                );
                setSuggestions(filteredSuggestions);
            }
        }
    }, [inputValue, showSuggestions]);

    const highlightSearchItem = (text) => {
        // Base case: If no text or search item is provided, return the text as-is
        if (!text || !searchItem) return text;

        // Handle arrays: Recursively apply the highlight to each element in the array
        if (Array.isArray(text)) {
            return text.map((item) => highlightSearchItem(item)); // Recursively handle each item in the array
        }

        // Handle strings: Apply highlighting if the text is a string
        if (typeof text === 'string') {
            const parts = text.split(new RegExp(`(${searchItem})`, 'gi')); // Split text into parts based on the search term
            return parts.map((part, index) =>
                part.toLowerCase() === searchItem.toLowerCase() ? (
                    <b key={index} style={{ color: '#fe4202' }}>{part}</b> // Highlight matching parts
                ) : (
                    part // Return non-matching parts unchanged
                )
            );
        }

        // Log an error and return the text as-is if it's neither a string nor an array
        console.error("Expected a string or an array but received:", typeof text, text);
        return text;
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (showOptionMatch || showSuggestions) {
                if (highlightedIndex >= 0) {
                    if (showSuggestions) {
                        handleSuggestionClick(suggestions[highlightedIndex]);
                    } else if (showOptionMatch && selectedOption === 'disease') {
                        const selectedOptionItem = diseaseOptions[highlightedIndex];
                        handleDiseaseClick(selectedOptionItem.disease, selectedOptionItem.type);
                    } else if (showOptionMatch && selectedOption === 'protein') {
                        const selectedOptionItem = proteinOptions[highlightedIndex];
                        handleProteinClick(selectedOptionItem.protein, selectedOptionItem.type);
                    } else if (showOptionMatch && selectedOption === 'drug') {
                        const selectedOptionItem = drugOptions[highlightedIndex];
                        handleDrugClick(selectedOptionItem.drug, selectedOptionItem.type);
                    }
                }
            } else {
                handleIconClick();
            }
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
                if (showSuggestions) {
                    const maxIndex = suggestions.length - 1;
                    return prevIndex < maxIndex ? prevIndex + 1 : 0;
                } else if (showOptionMatch && selectedOption === 'disease') {
                    const maxIndex = diseaseOptions.length - 1;
                    return prevIndex < maxIndex ? prevIndex + 1 : 0;
                } else if (showOptionMatch && selectedOption === 'protein') {
                    const maxIndex = proteinOptions.length - 1;
                    return prevIndex < maxIndex ? prevIndex + 1 : 0;
                } else if (showOptionMatch && selectedOption === 'drug') {
                    const maxIndex = drugOptions.length - 1;
                    return prevIndex < maxIndex ? prevIndex + 1 : 0;
                } else {
                    return prevIndex;
                }
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => {
                if (showSuggestions) {
                    const maxIndex = suggestions.length - 1;
                    return prevIndex > 0 ? prevIndex - 1 : maxIndex;
                } else if (showOptionMatch && selectedOption === 'disease') {
                    const maxIndex = diseaseOptions.length - 1;
                    return prevIndex > 0 ? prevIndex - 1 : maxIndex;
                } else if (showOptionMatch && selectedOption === 'protein') {
                    const maxIndex = proteinOptions.length - 1;
                    return prevIndex > 0 ? prevIndex - 1 : maxIndex;
                } else if (showOptionMatch && selectedOption === 'drug') {
                    const maxIndex = drugOptions.length - 1;
                    return prevIndex > 0 ? prevIndex - 1 : maxIndex;
                } else {
                    return prevIndex;
                }
            });
        }
    };

    useEffect(() => {
        if (highlightedIndex >= 0) {
            if (showSuggestions && suggestionsRef.current) {
                const element = suggestionsRef.current.children[highlightedIndex];
                if (element) {
                    element.scrollIntoView({ block: 'nearest' });
                }
            } else if (showOptionMatch && optionMatchRef.current) {
                const elements = optionMatchRef.current.querySelectorAll('li');
                const element = elements[highlightedIndex];
                if (element) {
                    element.scrollIntoView({ block: 'nearest' });
                }
            }
        }
    }, [highlightedIndex, showSuggestions, showOptionMatch]);

    // Expose a method to update the input value from the parent
    useImperativeHandle(ref, () => ({
        updateInputValue: (text) => {
            setInputValue(text);
            if (inputRef.current) {
                inputRef.current.focus(); // Optional: Bring focus to the input
            }
        },
    }));
    return (
        <div className="relative w-full">
            {showSuggestions && suggestions.length > 0 && (
                <ul ref={suggestionsRef} className="absolute bg-white border border-gray-300 rounded-md mt-1 w-full shadow-lg max-h-48 overflow-y-auto" style={{ bottom: 60 }}>
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            className={`cursor-pointer py-2 px-4 hover:bg-gray-200 ${index === highlightedIndex ? 'bg-gray-200' : ''}`}
                        >
                            {highlightSearchItem(suggestion)}
                        </li>
                    ))}
                </ul>
            )}
            {showOptionMatch && selectedOption === 'disease' && searchItem.length > 0 && (
                <ul ref={optionMatchRef} className="absolute bg-white border border-gray-300 rounded-md w-full shadow-lg max-h-60 overflow-y-auto" style={{ bottom: 60 }}>
                    {diseaseOptions.length > 0 ? (
                        <>
                            {diseaseOptions.map((option, index) => (
                                <React.Fragment key={index}>
                                    {/* Insert section header if necessary */}
                                    {index === 0 || diseaseOptions[index - 1].section !== option.section ? (
                                        <>
                                            {index !== 0 && <hr />}
                                            <h1 className='center bg-gray-200 p-2 font-bold'>{option.section}</h1>
                                            <hr />
                                        </>
                                    ) : null}
                                    <li
                                        onClick={() => handleDiseaseClick(option.disease, option.type)}
                                        className={`cursor-pointer py-2 px-4 hover:bg-gray-100 ${index === highlightedIndex ? 'bg-gray-100' : ''}`}
                                    >
                                        {highlightSearchItem(option.displayText)}
                                    </li>
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <li className="cursor-default py-2 px-4 text-gray-500">
                            No matches found
                        </li>
                    )}
                </ul>
            )}

            {showOptionMatch && selectedOption === 'protein' && searchItem.length > 0 && (
                <ul ref={optionMatchRef} className="absolute z-10 bg-white border border-gray-300 rounded-md w-full shadow-lg max-h-60 overflow-y-auto" style={{ bottom: 60 }}>
                    {proteinOptions.length > 0 ? (
                        <>
                            {proteinOptions.map((option, index) => (
                                <React.Fragment key={index}>
                                    {/* Insert section header if necessary */}
                                    {index === 0 || proteinOptions[index - 1].section !== option.section ? (
                                        <>
                                            {index !== 0 && <hr />}
                                            <h1 className='center bg-gray-200 p-2 font-bold'>{option.section}</h1>
                                            <hr />
                                        </>
                                    ) : null}
                                    <li
                                        onClick={() => handleProteinClick(option.protein, option.type)}
                                        className={`cursor-pointer py-2 px-4 hover:bg-gray-100 ${index === highlightedIndex ? 'bg-gray-100' : ''}`}
                                    >
                                        {highlightSearchItem(option.displayText)}
                                    </li>
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <li className="cursor-default py-2 px-4 text-gray-500">
                            No matches found
                        </li>
                    )}
                </ul>
            )}

            {showOptionMatch && selectedOption === 'drug' && searchItem.length > 0 && (
                <ul ref={optionMatchRef} className="absolute z-10 bg-white border border-gray-300 rounded-md w-full shadow-lg max-h-60 overflow-y-auto" style={{ bottom: 60 }}>
                    {drugOptions.length > 0 ? (
                        <>
                            {drugOptions.map((option, index) => (
                                <React.Fragment key={index}>
                                    {/* Insert section header if necessary */}
                                    {index === 0 || drugOptions[index - 1].section !== option.section ? (
                                        <>
                                            {index !== 0 && <hr />}
                                            <h1 className='center bg-gray-200 p-2 font-bold'>{option.section}</h1>
                                            <hr />
                                        </>
                                    ) : null}
                                    <li
                                        onClick={() => handleDrugClick(option.drug, option.type)}
                                        className={`cursor-pointer py-2 px-4 hover:bg-gray-100 ${index === highlightedIndex ? 'bg-gray-100' : ''}`}
                                    >
                                        {highlightSearchItem(option.displayText)}
                                    </li>
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <li className="cursor-default py-2 px-4 text-gray-500">
                            No matches found
                        </li>
                    )}
                </ul>
            )}
            <div className="relative w-full flex">
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                    className="w-full px-4 py-3 pr-10 border border-black rounded-lg shadow-sm focus:outline-none focus:ring-0 focus:border-[#FE4202]"
                    placeholder="Mention @"
                />
                {inputValue ? (
                    loading ? ( // Block icon when loading
                        <div className="absolute right-2 top-1/2 transform -translate-y-1/2 text-3xl text-gray-400">
                            <PiArrowSquareRightFill className="cursor-not-allowed" title='Enter' />
                        </div>
                    ) : (
                        <PiArrowSquareRightFill
                            onClick={handleIconClick}
                            className="absolute text-[#FE2402] text-3xl right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        />)
                ) : (
                    <PiArrowSquareRightLight
                        onClick={handleIconClick}
                        title='Enter'
                        className="absolute text-[#FE2402] text-3xl right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    />
                )}
            </div>
        </div>
    );

});

export default SearchInput;