import React, { useContext } from 'react'
import logo from '../../Assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';

const LogoutPage = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const HandleLogOut = () => {
        axios.get(`${BACKEND_URL}/auth/logout`)
        .then(response =>{
            // console.log(response);
            if(response.data.status){
                alert('logged out sucessfully')
                setIsAuthenticated(false);
                navigate('/');
            }
        })
        .catch(error => {
            console.error('Logout failed:', error);
            // Handle logout error (e.g., show error message to user)
        });
    }
  return (
    
    <>
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Covaln Logo" className="h-12" />
        </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <button
              type="button"
              placeholder="example@covaln.com"
              className="w-full py-2 px-3 rounded-md text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              onClick={HandleLogOut}
            >LogOut
            </button>
          </div>
 
          <div className="text-center mt-4">
            <p className="text-gray-600 text-sm">Do you want to login? <Link to="/login" className="text-[#FE4202] hover:underline">Login</Link></p>
          </div>
      </div>
     
    </div></>
  )
}

export default LogoutPage