import React, { useState, useEffect, useMemo } from 'react';
import TableView from './TableView';
import AdmetPopup from './AdmetPopup';
import MoleculeEditorPopup from './MoleculeEditorPopup';
import OCL from 'openchemlib';
import { useDispatch, useSelector } from 'react-redux';
import { fetchParameters, userDetails } from '../../redux/Actions/action';
import axios from 'axios';
import DockingPopup from './DockingPopup';
import db from '../../db';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PredictNovelDrugsInterface = (data) => {
  const [tableData, setTableData] = useState([]);
  const [selectedAdmet, setSelectedAdmet] = useState(null);
  const [selectedDrugName, setSelectedDrugName] = useState('');
  const [editSmiles, setEditSmiles] = useState(null);
  const [dockSmiles, setDockSmiles] = useState(null);
  const [error, setError] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [predTempResult, setPredTempResult] = useState('');
  const [predTempAdmet_result, setPredAdmet_result] = useState('');
  const [selectedPdbId, setSelectedPdbId] = useState('');

  const conv_ID = useSelector((state) => state.conv_ID);
  const UserData = useSelector((state) => state.UserDetails);
  const novelParameters = useSelector((state) => state.parameters.predictNovelDrugs);
  const dockParameters = useSelector((state) => state.parameters.dock);
  // console.log("novelParameters",novelParameters)
  // console.log("dockParameters",dockParameters)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchParameters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(userDetails());
  }, [dispatch]);

  const proteinId = useMemo(() => data?.data?.PROTEIN, [data]);

  useEffect(() => {
    if (!proteinId) {
      setError('No data provided');
      return;
    }
    const fetchData = async () => {
      try {
        // Assigning parameters with default values using logical OR (||)
        // const iterations = novelParameters.iterations || 1;
        // const topK = novelParameters.topK || 100;
        // const seedProbThreshold = novelParameters.seedProbThreshold || 0.8;
        // const iterProbThreshold = novelParameters.iterProbThreshold || 0.8;
        // const simSearchThreshold = novelParameters.simSearchThreshold || 0.8;
        // const simSearchWorkers = novelParameters.simSearchWorkers || 1;

        const iterations =  1;
        const topK = 100;
        const seedProbThreshold =  0.75;
        const iterProbThreshold = 0.7;
        const simSearchThreshold =  0.75;
        const simSearchWorkers = 1;

        const payload = {
          target: proteinId,
          num_iterations: iterations,
          top_k: topK,
          seed_prob_threshold: seedProbThreshold,
          iter_prob_threshold: iterProbThreshold,
          sim_search_threshold: simSearchThreshold,
          sim_search_workers: simSearchWorkers,
        };
        console.log(payload)
        // Check if data is cached in IndexedDB
        const cachedData = await db.proteins.get(`novel_${proteinId}`);
        if (cachedData) {
          console.log('Using cached data:', cachedData);
          setTableData(cachedData.data);
          return;
        }

        // Fetch the relations based on the protein
        const response = await axios.get(`${BACKEND_URL}/relations/${proteinId}`);
        if (response.data.found) {
          try {
            // Call your server-side API
            const predictResponse = await axios.post(`${BACKEND_URL}/auth/api/predict-novelDrugs`, payload);

            const { predTempResult, predAdmet_result } = predictResponse.data;

            setPredTempResult(predTempResult);
            setPredAdmet_result(predAdmet_result);

            setResponseMessage('Request successful!');
          } catch (error) {
            setResponseMessage(`Request failed. Error: ${error.message}`);
            console.error(`Request failed. Error: ${error.message}`);
          }
        } else {
          setError(`No predicted drugs for the given target`);
          return;
        }
      } catch (error) {
        console.error('Error fetching data for predicting drugs:', error);
        setError('Error fetching data for predicting drugs');
      }
    };

    fetchData();
  }, [proteinId, novelParameters]);

  // Function to limit cache size
  const limitCacheSize = async (maxSize = 50) => {
    const count = await db.proteins.count();
    if (count > maxSize) {
      const itemsToDelete = await db.proteins.orderBy('timestamp').limit(count - maxSize).toArray();
      const keysToDelete = itemsToDelete.map(item => item.id);
      await db.proteins.bulkDelete(keysToDelete);
      console.log('Evicted old cache entries:', keysToDelete);
    }
  };

  useEffect(() => {
    if (!predTempResult || !predTempAdmet_result) return;

    const fetchData = async () => {
      try {
        const predictDrugsData = predTempResult.map((item) => ({
          ID: item.MCULE_ID,
          C_SMILES: item.C_SMILES,
          PRED: item.PRED
        }));

        // Create a mapping of C_SMILES to admet results
        const admetData = predictDrugsData.reduce((acc, drug, index) => {
          acc[drug.C_SMILES] = predTempAdmet_result[index] || {};
          return acc;
        }, {});

        // Map predictDrugsData directly to the required table data structure
        const drugsData = predictDrugsData.map((predictItem) => ({
          id: predictItem.ID || 'N/A',
          smiles: predictItem.C_SMILES,
          truncatedSmiles:
            predictItem.C_SMILES.length > 10
              ? `${predictItem.C_SMILES.substring(0, 10)}...`
              : predictItem.C_SMILES,
          cov: predictItem.PRED,
          dock: 'N/A',
          admet: admetData[predictItem.C_SMILES] || {},
          structure: 'View',
        }));

        console.log('Prepared tableData:', drugsData); // Debugging statement
        setTableData(drugsData);

        // Save to IndexedDB
        await db.proteins.put({
          id: `novel_${proteinId}`,
          name: `Predicted Novel Drugs for ${proteinId}`,
          data: drugsData,
          timestamp: Date.now(),
        });

        console.log('Data cached successfully');

        // Evict old cache entries if necessary
        await limitCacheSize(50);

      } catch (error) {
        console.error('Error preparing data:', error);
      }
    };

    fetchData();
  }, [predTempResult, predTempAdmet_result, proteinId]);

  const handleDockSmiles = async (smiles, selectedPdbId) => {
    // Set dock status to 'Docking' for matching SMILES before making the API call
    const dockingTableData = tableData.map(item => {
      const match = smiles.includes(item.smiles);
      if (match) {
        return {
          ...item,
          dock: 'Docking' // Set dock to 'Docking' while processing
        };
      }
      return item;
    });

    // Update the table to reflect the 'Docking' status
    setTableData(dockingTableData);

    console.log("Docking with SMILES:", smiles);
    console.log("Docking with selected PDB ID:", selectedPdbId);
    console.log("PROTEIN ID:", proteinId);

    const exhaustiveness = dockParameters.exhaustiveness || 8;
    const cpus = dockParameters.cpus || 2;

    // Construct the payload
    const Dock = {
      uniprot_acc: proteinId,
      pdb_id: selectedPdbId,
      exhaustiveness,
      cpus,
      smiles_list: smiles
    };
    const payload = {
      email: UserData.email,
      conversationID: conv_ID,
      queryID: data.Query_ID,
      DockValues: Dock,
    };

    console.log(payload)

    try {
      const response = await fetch(`${BACKEND_URL}/auth/save-dock-result`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      const result = await response.json();
      // Handle the response from the server
      console.log(result)

      // Create an object to store the results for each SMILES
      const dockingData = smiles.map(eachsmile => {
        const filteredResults = result.results.filter(result => result.smiles === eachsmile);

        return {
          smiles: eachsmile,
          dockData: filteredResults.map(result => ({
            model: result.model,
            'binding affinity': result['binding affinity'],
            'rmsd l.b.': result['rmsd l.b.'],
            'rmsd u.b.': result['rmsd u.b.'],
          }))
        };
      });

      // Output the docking data
      console.log(dockingData);

      if (result) {
        console.log('Molecule saved successfully');

        // Update tableData again to set dock to 'View' after docking completes
        const updatedTableData = dockingTableData.map(item => {
          const matchData = dockingData.find(dock => dock.smiles === item.smiles);
          if (matchData) {
            return {
              ...item,
              dock: 'View', // Set dock to 'View' after successful docking
              dockResult: matchData.dockData // Include the extra property from dockingData
            };
          }
          return item;
        });

        // Update state with the new table data
        setTableData(updatedTableData);

        // Optionally, update the cache with the new docking results
        await db.proteins.put({
          id: `novel_${proteinId}`,
          name: `Predicted Novel Drugs for ${proteinId}`,
          data: updatedTableData,
          timestamp: Date.now(),
        });
        console.log('Cache updated with docking results');

      } else {
        console.error('Error saving molecule:', result.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setDockSmiles(null)
  };

  const handleSaveSmiles = async (editedMoleculesArray = []) => {
    if (!Array.isArray(editedMoleculesArray) || editedMoleculesArray.length === 0) {
      console.error('No molecules were edited:', editedMoleculesArray);
      setEditSmiles(null);
      return;
    }

    // Prepare the molecule data to send to the backend
    const moleculeArray = editedMoleculesArray.map(({ initialSmiles, currentSmiles }) => {
      const editedMolecule = tableData.find((item) => item.smiles === initialSmiles);
      return {
        sourceMoleculeID: editedMolecule?.id || '',
        sourceMoleculeSMILES: initialSmiles,
        editedMoleculeSMILES: currentSmiles,
      };
    });

    // Update tableData for edited molecules
    const updatedTableData = tableData.map((item) => {
      const editedMolecule = editedMoleculesArray.find(
        (mol) => mol.initialSmiles === item.smiles
      );
      if (editedMolecule) {
        const molecule = OCL.Molecule.fromSmiles(editedMolecule.currentSmiles);
        const svg = molecule.toSVG(300, 300);
        return {
          ...item,
          smiles: editedMolecule.currentSmiles,
          truncatedSmiles:
            editedMolecule.currentSmiles.length > 10
              ? `${editedMolecule.currentSmiles.substring(0, 10)}...`
              : editedMolecule.currentSmiles,
          editedMoleculeSvg: btoa(svg),
        };
      } else {
        return item;
      }
    });

    setTableData(updatedTableData);

    // Proceed with sending to the backend
    try {
      const payload = {
        email: UserData.email,
        conversationID: conv_ID,
        queryName: `Predicted Novel drugs for ${proteinId}`,
        queryID: data.Query_ID,
        proteinID: proteinId,
        molecules: moleculeArray,
      };
      const response = await fetch(`${BACKEND_URL}/auth/save-edited-molecule`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if (result.status) {
        console.log('Molecule saved successfully');
      } else {
        console.error('Error saving molecule:', result.message);
      }

      // Optionally, update the cache with the edited molecules
      await db.proteins.put({
        id: `novel_${proteinId}`,
        name: `Predicted Novel Drugs for ${proteinId}`,
        data: updatedTableData,
        timestamp: Date.now(),
      });
      console.log('Cache updated with edited molecules');
    } catch (error) {
      console.error('Error:', error);
    }

    setEditSmiles(null);
  };

  return (
    <>
      <div className="p-4">
        {error ? (
          <div className="text-xl font-bold mb-4 text-gray-400">{error} <span className='pl-2 text-[#494949]'>{proteinId}</span></div>
        ) : (
          <>
            <h2 className="text-xl font-bold mb-4 text-[#494949]">Predicted novel drugs for {proteinId}</h2>
            {tableData.length > 0 ? (
              <TableView
                argument={data}
                data={tableData}
                selectable={true}
                onAdmetClick={(admet, drugName) => {
                  setSelectedAdmet(admet);
                  setSelectedDrugName(drugName);
                }}
                onEditSmiles={(smiles) => setEditSmiles(smiles)}
                onDockSmiles={(smiles) => setDockSmiles(smiles)}
                selectedPdbId={selectedPdbId}
              />
            ) : (
              <div className="text-xl font-bold mb-4 text-gray-300">Loading....</div>
            )}
          </>
        )}
      </div>

      {selectedAdmet && (
        <AdmetPopup
          admet={selectedAdmet}
          drugName={selectedDrugName}
          onClose={() => setSelectedAdmet(null)}
        />
      )}

      {editSmiles && (
        <MoleculeEditorPopup
          smilesList={Array.isArray(editSmiles) ? editSmiles : [editSmiles]} // Ensure smilesList is an array
          onSaveAll={handleSaveSmiles}
          onClose={() => setEditSmiles(null)}
        />
      )}

      {dockSmiles && (
        <DockingPopup
          proteinID={proteinId}
          smilesList={Array.isArray(dockSmiles) ? dockSmiles : [dockSmiles]}
          onDock={(smiles, selectedPdbId) => {
            setSelectedPdbId(selectedPdbId);
            handleDockSmiles(smiles, selectedPdbId);
          }}
          onClose={() => setDockSmiles(null)}
        />
      )}
    </>
  );
};

export default PredictNovelDrugsInterface;