import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import * as $3Dmol from '3dmol';

const MoleculeViewer = ({ pdb }) => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const viewerRef = useRef(null);
    const [viewer, setViewer] = useState(null);
    const [color, setColor] = useState('spectrum');
    const [error, setError] = useState('');

    useEffect(() => {
        // Check if the element exists and viewer is not already initialized
        if (!viewerRef.current || viewer) return;

        const element = viewerRef.current;
        const config = { backgroundColor: 'black' };
        const newViewer = $3Dmol.createViewer(element, config);
        setViewer(newViewer);

        return () => {
            newViewer.removeAllModels();
            newViewer.clear();
        };
    }, [viewer]);

    useEffect(() => {
        if (!viewer) return;

        const pdbUri = `${BACKEND_URL}/gridfs/pdb/${pdb}`;
        setError(''); // Clear any previous errors

        $.ajax(pdbUri, {
            success: function (data) {
                if (data.error) {
                    setError(data.error); // Set error message if the response contains an error
                } else {
                    viewer.addModel(data, "pdb");
                    viewer.setStyle({}, { cartoon: { color: color } });
                    viewer.zoomTo();
                    viewer.render();
                    viewer.zoom(1.2, 1000);
                }
            },
            error: function (hdr, status, err) {
                setError(`No PDB structure available for this protein`); // Set error state
            }
        });

        return () => {
            viewer.removeAllModels();
        };
    }, [pdb, color, viewer, BACKEND_URL]);

    const handleStyleChange = (style) => {
        if (viewer) {
            viewer.setStyle({}, style);
            viewer.render();
        }
    };

    const handleColorChange = (newColor) => {
        setColor(newColor);
    };

    const colorSS = () => {
        if (viewer) {
            const colorAsSnake = (atom) => atom.resi % 2 === 0 ? 'white' : 'green';
            viewer.setStyle({}, { cartoon: { colorfunc: colorAsSnake } });
            viewer.render();
        }
    };

    const addSurface = (type, options) => {
        if (viewer) {
            viewer.addSurface(type, options, { hetflag: false, chain: 'A' }, { hetflag: false, chain: 'A' });
            viewer.render();
        }
    };

    const removeSurfaces = () => {
        if (viewer) {
            viewer.removeAllSurfaces();
            viewer.render();
        }
    };

    return (
        <div>
            {error ? (
                <div className="text-red-500 mt-4">{error}</div>
            ) : (
                <>
                    <div className="dropdown-container">
                        <label htmlFor="colorDropdown">Select Color: </label>
                        <select
                            id="colorDropdown"
                            className="px-2 py-1 border border-gray-300 rounded"
                            onChange={(e) => handleColorChange(e.target.value)}
                        >
                            <option value="spectrum">spectrum</option>
                            <option value="green">Green</option>
                            <option value="blue">Blue</option>
                            <option value="red">Red</option>
                            <option value="yellow">Yellow</option>
                        </select>
                    </div>
                    <div
                        id="container-01"
                        className="mol-container"
                        ref={viewerRef}
                        style={{ width: '100%', height: '400px' }}
                    ></div>

                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={() => handleStyleChange({ stick: {} })}>Stick</button>
                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={() => handleStyleChange({ line: {} })}>Line</button>
                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={() => handleStyleChange({ cross: { linewidth: 2 } })}>Cross</button>
                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={() => handleStyleChange({ sphere: {} })}>Sphere</button>
                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={() => handleStyleChange({ cartoon: {} })}>Cartoon</button>
                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={colorSS}>Color SS</button>
                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={() => addSurface($3Dmol.SurfaceType.VDW, {})}>Surface1</button>
                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={() => addSurface($3Dmol.SurfaceType.MS, { map: { prop: 'partialCharge', scheme: new $3Dmol.Gradient.RWB(-0.6, 0.6) }, opacity: 0.85 })}>Surface2</button>
                    <button className="px-4 py-2 text-black rounded hover:bg-orange-500" onClick={removeSurfaces}>RM Surfaces</button>
                </>
            )}
        </div>
    );
};

export default MoleculeViewer;