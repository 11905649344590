import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import MoleculeViewer from './components/Pages/MolecularViewer';
import LoginPage from './components/Account/LoginPage';
import SignUpPage from './components/Account/SignUpPage';
import HomePage from './components/Pages/HomePage';
import Sidebar from './components/Sidebar';
import HistoryPage from './components/Pages/HistoryPage';
import LogoutPage from './components/Account/LogoutPage';
import ForgotPassword from './components/Account/ForgotPassword';
import ConfirmPage from './components/Account/ConfirmPage';
import ResetPassword from './components/Account/ResetPassword';
import LandingPage from './components/Pages/LandingPage';
import Conversation from './components/Pages/Conversation';
import UserDetailPage from './components/Pages/UserDetailPage';
import UserAdminPage from './components/Pages/UserAdminPage';
import UserProfilePage from './components/Pages/UserProfilePage';
import PredictEditedMol from './components/Pages/PredictEditedMol';
import EditedMolecules from './components/Pages/EditedMolecules';
import { AuthProvider } from './AuthContext';
import HelpPage from './components/Pages/HelpPage';

const AppContent = () => {
  const location = useLocation();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  // List of exact paths where the sidebar should not be shown
  const noSidebarExactPaths = [
    "/",
    "/login",
    "/signup",
    "/forgotPassword",
    "/logout",
    "/confirm",
    "/help"
  ];

  // List of paths that include parameters where the sidebar should not be shown
  const noSidebarParamPaths = [
    "/reset-password/"
  ];

  // Check if the current path matches any of the no-sidebar exact paths
  const isExactMatch = noSidebarExactPaths.includes(location.pathname);

  // Check if the current path matches any of the no-sidebar parameter paths
  const isParamMatch = noSidebarParamPaths.some(path => location.pathname.startsWith(path));

  const shouldHideSidebar = isExactMatch || isParamMatch;

  return (
    <div className="bg-gray-50 flex">
      {!shouldHideSidebar && (
        <Sidebar expanded={sidebarExpanded} setExpanded={setSidebarExpanded} />
      )}
      <div
        className={`flex-grow ${!shouldHideSidebar ? (sidebarExpanded ? 'ml-60' : 'ml-16') : ''
          } transition-all duration-300 overflow-y-auto min-h-screen`}
      >
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/confirm" element={<ConfirmPage />} />
          <Route path="/3DMol" element={<MoleculeViewer />} />
          <Route path="/c/:conv_ID" element={<Conversation />} />
          <Route path="/user/:userId" element={<UserDetailPage />} />
          <Route path="/useradmin" element={<UserAdminPage />} />
          <Route path="/profile" element={<UserProfilePage />} />
          <Route path="/edited" element={<EditedMolecules />} />
          <Route path="/predictEditedMol" element={<PredictEditedMol />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  </BrowserRouter>
);

export default App;