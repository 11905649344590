import React, { useEffect, useState } from 'react';
import axios from 'axios';

const DockingPopup = ({ smilesList, onClose, onDock, proteinID }) => {

    const [dockingResult, setDockingResult] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedPdbId, setSelectedPdbId] = useState(null); // Track selected PDB ID
    const [hoveredCardId, setHoveredCardId] = useState(null);
    const [dockButtonEnabled, setDockButtonEnabled] = useState(false); // Track if the dock button should be enabled
    const [smilesArray, setSmilesArray] = useState(Array.isArray(smilesList) ? smilesList : []);
    const [isLoading, setIsLoading] = useState(false); // New loading state
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    // Trigger the API request when proteinID is available
    useEffect(() => {
        // const proteinID = 'P11229';
        if (proteinID) {
            const payload = {
                // smilesList,
                proteinID
            };

            const sendRequest = async () => {
                try {
                    const dockResponse = await axios.post(`${BACKEND_URL}/auth/docking`, payload);
                    console.log('Server response:', dockResponse.data);
                    if (dockResponse.data.result?.pdb_details && dockResponse.data.result.pdb_details.length > 0) {
                        const uniquePdbDetails = Array.from(new Set(dockResponse.data.result.pdb_details.map(item => item.pdb_id)))
                            .map(pdb_id => dockResponse.data.result.pdb_details.find(item => item.pdb_id === pdb_id));

                        setDockingResult(uniquePdbDetails);
                    } else if (dockResponse.data.detail) {
                        setErrorMessage(dockResponse.data.detail);  // Capture the error message
                    }
                } catch (error) {
                    // console.error('Error sending request:', error);
                    // setErrorMessage('An error occurred while processing the request.');
                    if (error.response && error.response.data && error.response.data.detail) {
                        setErrorMessage(error.response.data.detail);  // Display the specific detail message
                    } else {
                        console.error('Error sending request:', error);
                        setErrorMessage('An error occurred while processing the request.');
                    }
                }
            };

            sendRequest();
        }
    }, [proteinID]);

    const handleDock = async () => {
        // Perform docking actions here based on the selected PDB ID
        // if (selectedPdbId) {
        //     console.log(`Docking with PDB ID: ${selectedPdbId}`);
        // }
        setIsLoading(true); // Show loading overlay
        // Perform docking actions here based on the selected PDB ID
        await onDock(smilesArray, selectedPdbId);
        // setIsLoading(false); // Hide loading overlay after docking is done
    };

    const handleClick = (pdb_id) => {
        setSelectedPdbId(pdb_id); // Set the selected PDB ID on single click
        setDockButtonEnabled(true); // Enable the dock button
    };

    const handleMouseEnter = (pdb_id) => {
        setHoveredCardId(pdb_id); // Set the hovered card
    };

    const handleMouseLeave = () => {
        setHoveredCardId(null); // Clear the hovered card when the mouse leaves
    };

    // Dynamically calculate width based on number of cards
    const popupWidth = dockingResult && dockingResult.length > 0
        ? Math.min(96, 40 * dockingResult.length) + 'vw' // Adjust width formula as needed
        : '50vw'; // Default width if no cards are present

    return (
        <>
            {isLoading ? (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="text-white text-2xl font-bold">Docking...</div>
                </div>
            ) :
                (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
                        <div
                            className="bg-white p-4 rounded shadow-lg relative"
                            style={{ width: popupWidth }} // Apply dynamic width
                        >
                            {/* Close button */}
                            <button
                                className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                                onClick={onClose}
                            >
                                &times;
                            </button>

                            <div className="text-lg font-bold mb-4">Choose the structure for docking</div>

                            {/* Display error message if any */}
                            {errorMessage && (
                                <div className="text-red-500">
                                    {errorMessage}
                                </div>
                            )}

                            {/* Display PDB details if available */}
                            {dockingResult && dockingResult.length > 0 ? (<>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {dockingResult.map((pdb, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleClick(pdb.pdb_id)}  // Single click to select
                                            className={`border p-4 rounded shadow-sm cursor-pointer transition duration-200
                                ${selectedPdbId === pdb.pdb_id ? 'bg-blue-200 border-blue-400' : 'hover:bg-gray-100'}
                                `}
                                            title={selectedPdbId === pdb.pdb_id ? 'Selected' : 'Click to select'}
                                            onMouseEnter={() => handleMouseEnter(pdb.pdb_id)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <h3 className="text-lg font-bold mb-2">{pdb.pdb_id} {pdb.best_coverage ? " (Best coverage structure) " : pdb.best_structure ? " (Best resolution structure) " : ""}</h3>
                                            <h3 className="text-lg font-bold mb-2">

                                            </h3>
                                            <p><strong>pdb_id:</strong> {pdb.pdb_id}</p>
                                            <p><strong>Resolution:</strong> {pdb.resolution} Å</p>
                                            <p><strong>Coverage:</strong> {pdb.coverage}</p>
                                            <p><strong>Experimental Method:</strong> {pdb.experimental_method}</p>
                                            <p><strong>Chain ID:</strong> {pdb.chain_id}</p>
                                            <p><strong>Start-End:</strong> {pdb.start} - {pdb.end}</p>
                                            <p><strong>UniProt Range:</strong> {pdb.uniprot_start} - {pdb.uniprot_end}</p>
                                        </div>
                                    ))}
                                    {/* Dock Button */}
                                </div>
                                <button
                                    onClick={handleDock}
                                    className={`px-3 py-1 my-2 border rounded transition duration-200
                                ${dockButtonEnabled ? 'bg-[#FE4202] text-white' : 'bg-gray-300 text-gray-600 cursor-not-allowed'}
                                `}
                                    disabled={!dockButtonEnabled}
                                    title={dockButtonEnabled ? 'Dock selected structure' : 'Select a structure to enable docking'}
                                >
                                    Dock
                                </button>
                            </>
                            ) : (
                                !errorMessage && <p className="text-center">No docking data available for this protein.</p>
                            )}


                        </div>
                    </div>
                )}

        </>

    );
};

export default DockingPopup;