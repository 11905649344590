import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Tab } from '@headlessui/react';
import ReactPaginate from 'react-paginate';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import admetMetadata from './admet_categories.json';

const ITEMS_PER_PAGE = 5;
const IMAGE_SIZE = { width: '150px', height: '150px' };

const AdmetPopup = ({ admet, drugName, onClose }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');

  // Get the categories from the metadata
  const categories = [...new Set(admetMetadata.map(item => item.category))];

  // Get the keys of the ADMET properties, excluding the images
  const getAdmetKeysByCategory = (category) => {
    return admetMetadata.filter(item => item.category === category).map(item => item.id);
  };

  const totalPages = (category) => {
    const keys = getAdmetKeysByCategory(category);
    return Math.ceil(keys.length / ITEMS_PER_PAGE);
  };

  const getAdmetMetadata = (id) => {
    return admetMetadata.find(item => item.id === id) || {};
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const renderAdmetData = (category) => {
    const keys = getAdmetKeysByCategory(category);
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const currentKeys = keys.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    return (
      <div>
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-[#EEEFF2]">
              <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">Property Name</th>
              <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">Prediction</th>
              <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">Drug Bank Percentile</th>
              <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">Units</th>
              <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">Accuracy</th>
            </tr>
          </thead>
          <tbody>
            {currentKeys.map((key) => {
              const metadata = getAdmetMetadata(key);
              const percentileKey = `${key}_drugbank_approved_percentile`;
              const accuracy = metadata.AUROC ?? metadata['R^2'];
              const accuracyValue = accuracy === null || isNaN(accuracy) ? 'N/A' : accuracy;

              return (
                <tr key={key} className="hover:bg-gray-100">
                  <td className="border px-4 py-2 text-sm text-gray-700">{metadata.name || key}</td>
                  <td className="border px-4 py-2 text-sm text-gray-700">{admet[key].toFixed(3)}</td>
                  <td className="border px-4 py-2 text-sm text-gray-700">{admet[percentileKey].toFixed(3) || 'N/A'}</td>
                  <td className="border px-4 py-2 text-sm text-gray-700">{metadata.units || 'N/A'}</td>
                  <td className="border px-4 py-2 text-sm text-gray-700">{accuracyValue}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex mt-4 items-center justify-end">
          <ReactPaginate
            previousLabel={<FiChevronLeft />}
            nextLabel={<FiChevronRight />}
            pageCount={totalPages(category)}
            onPageChange={handlePageClick}
            containerClassName={'flex items-center'}
            pageClassName={'mx-1'}
            activeClassName={'font-bold'}
            previousClassName={'mr-4'}
            nextClassName={'ml-4'}
            breakLabel={'...'}
            breakClassName={'mx-1'}
          />
        </div>
      </div>
    );
  };

  const renderImage = (imageBase64) => {
    const imageSrc = `data:image/svg+xml;base64,${imageBase64}`;
    return <img src={imageSrc} alt="ADMET SVG" style={IMAGE_SIZE} />;
  };

  useEffect(() => {
    Modal.setAppElement('body');
    if (categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedCategory]);

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      className="flex justify-center items-center fixed inset-0 bg-[rgba(0,0,0,0.5)]"
      overlayClassName="fixed inset-0 bg-[rgba(0,0,0,0.5)]"
      contentLabel="ADMET Details"
    >
      <div className="bg-[#EEEFF2] p-5 rounded-lg w-3/5 h-5/5 overflow-hidden">
        <button onClick={onClose} className="text-[#FE4202] text-lg float-right cursor-pointer">
          &times;
        </button>
        <h2 className="text-2xl mb-4">ADMET Details - {drugName}</h2>
        <div className="flex flex-row justify-around mb-4">
          {admet.radial_plot && (
            <div className="mb-4">
              <h3 className="text-md mb-2">Radial Plot</h3>
              {renderImage(admet.radial_plot)}
            </div>
          )}
          {admet.molecule_svg && (
            <div className="mb-4">
              <h3 className="text-md mb-2">Molecule SVG</h3>
              {renderImage(admet.molecule_svg)}
            </div>
          )}
        </div>
        <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 rounded-xl">
            {categories.map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  `w-full py-2.5 text-sm leading-5 font-medium focus:outline-none ${
                    selected ? 'text-[#FE4202] border-b-2 border-[#FE4202]' : 'hover:text-[#FE4202]'
                  }`
                }
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2 overflow-y-auto max-h-[calc(100vh-250px)]">
            {categories.map((category) => (
              <Tab.Panel key={category} className="bg-white rounded-xl p-3 shadow max-h-96">
                {renderAdmetData(category)}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Modal>
  );
}

export default AdmetPopup;