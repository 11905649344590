import React, { useState, useEffect, useContext, useRef } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { PiFlask } from 'react-icons/pi';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { IoPower } from 'react-icons/io5';
import { ChevronFirst, ChevronLast, MoreVertical } from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { updateConvID, userDetails } from '../redux/Actions/action';
import logo from '../Assets/logo.png';
import { SiMoleculer } from "react-icons/si";
import { CgProfile } from "react-icons/cg";
import { useSelector, useDispatch } from 'react-redux';
import { FiCheck, FiTrash2, FiX } from 'react-icons/fi';
import { AuthContext } from '../AuthContext';
import { MdEdit } from 'react-icons/md';

const Sidebar = ({ expanded, setExpanded }) => {
  // const [expanded, setExpanded] = useState(false);
  const [conv_ID, setConv_ID] = useState('');
  const [conversations, setConversations] = useState([]);
  const [deletingConversation, setDeletingConversation] = useState(null);
  const [showOptions, setShowOptions] = useState(false); // State to track options visibility

  const [editingConversation, setEditingConversation] = useState(null);
  const [newHistoryTitle, setNewHistoryTitle] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Make sure to extract user properties correctly
  const { username, email } = useSelector((state) => state.UserDetails);
  // // const token = localStorage.getItem('token');
  // const { setIsAuthenticated, token } = useContext(AuthContext);
  const { token } = useContext(AuthContext);
  const inputRef = useRef(null);
  const editContainerRef = useRef(null);
  const dropdownRef = useRef(null); // Ref for the dropdown menu
  const ignoreClickRef = useRef(false);
  const conversationListRef = useRef(null);

  const [selectedConversation, setSelectedConversation] = useState(null);


  useEffect(() => {
    dispatch(userDetails(token));
  }, [dispatch, token]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/auth/get-conversations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setConversations(response.data.conversations.reverse());
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          console.error('Error fetching conversations:', error);
        }
      }
    };

    fetchConversations();
  }, [token, navigate, BACKEND_URL, conversations]);

  const handleNewConversation = () => {
    const newConv_ID = uuidv4().replace(/-/g, '').trim();
    localStorage.setItem('conv_ID', newConv_ID);
    setConv_ID(newConv_ID);
    dispatch(updateConvID(newConv_ID));
    navigate('/home');
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${BACKEND_URL}/auth/delete-conversation/${deletingConversation.conversationID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setConversations((prevConversations) =>
        prevConversations.filter(
          (conv) => conv.conversationID !== deletingConversation.conversationID
        )
      );
      setDeletingConversation(null);
      // Redirect to home page after deletion
      navigate('/home');
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };
  const confirmDelete = (event, conversation) => {
    event.stopPropagation();
    setDeletingConversation(conversation);
  };

  const cancelDelete = () => {
    setDeletingConversation(null);
  };

  const handleExistedConv_ID = (existedConv_ID) => {
    dispatch(updateConvID(existedConv_ID));
  };

  const resetConv_ID = () => {
    const logoutConv_ID = uuidv4().replace(/-/g, '').trim();
    dispatch(updateConvID(logoutConv_ID));
  };

  const openEditPopup = (conversation) => {
    setEditingConversation(conversation);
    // setNewHistoryTitle(conversation.queries?.[0]?.QueryName || '');
    const currentTitle = conversation.Summary?.[0]?.history_title || conversation.queries?.[0]?.QueryName || '';
    setNewHistoryTitle(currentTitle); // Initialize with QueryName or history_title
  };
  // const handleSave = async () => {
  //   if (!editingConversation) return;
  //   try {
  //     // Update history_title in Summary
  //     const updatedSummary = { ...editingConversation.Summary[0], history_title: newHistoryTitle };

  //     // Update SimpleQuery in queries
  //     const updatedQueries = editingConversation.queries?.map((query, index) => {
  //       if (index === 0) {
  //         return { ...query, QueryName: newHistoryTitle };
  //       }
  //       return query;
  //     }) || [];

  //     const updatedConversation = {
  //       ...editingConversation,
  //       Summary: [updatedSummary],
  //       queries: updatedQueries,
  //     };

  //     // API call to save updated conversation
  //     await axios.post(
  //       `${BACKEND_URL}/auth/save-conversation`,
  //       { conversations: updatedConversation },
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );

  //     // Update frontend state
  //     setConversations((prevConversations) =>
  //       prevConversations.map((conv) =>
  //         conv.conversationID === editingConversation.conversationID ? updatedConversation : conv
  //       )
  //     );

  //     // Reset editing state
  //     setEditingConversation(null);
  //     setNewHistoryTitle('');
  //   } catch (error) {
  //     console.error('Error saving conversation:', error);
  //   }
  // };

  const handleSave = async () => {
    if (!editingConversation) return;

    try {
      // Create updated Summary with new history_title
      const updatedSummary = {
        ...editingConversation.Summary[0],
        history_title: newHistoryTitle
      };

      // Update the conversation object with updated Summary
      const updatedConversation = {
        ...editingConversation,
        Summary: [updatedSummary],
      };

      // API call to save updated conversation
      await axios.post(
        `${BACKEND_URL}/auth/save-conversation`,
        { conversations: updatedConversation },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update the conversation in the frontend state
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.conversationID === editingConversation.conversationID
            ? updatedConversation
            : conv
        )
      );

      // Reset editing state
      setEditingConversation(null);
      setNewHistoryTitle('');
    } catch (error) {
      console.error('Error saving conversation:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingConversation(null);
    setNewHistoryTitle('');
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      handleCancelEdit();
    } else if (e.key === 'Enter') {
      handleSave();
    }
  };
  useEffect(() => {
    if (editingConversation && inputRef.current) {
      inputRef.current.focus();
      const length = newHistoryTitle.length;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [editingConversation, newHistoryTitle]);
  // Effect to handle clicks outside the editing area
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editContainerRef.current &&
        !editContainerRef.current.contains(event.target)
      ) {
        handleCancelEdit();
      }
    };
    if (editingConversation) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingConversation]);
  // Effect to handle clicks outside the dropdown menu
  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        return; // If clicked inside the dropdown, do nothing
      }

      if (ignoreClickRef.current) {
        ignoreClickRef.current = false; // Reset flag immediately after it's used
        return; // Ignore click outside on first click (when opening dropdown)
      }

      setDropdownVisible(null); // Close the dropdown on outside click
    };

    // Add event listener when dropdown is visible
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutsideDropdown);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    }

    // Cleanup event listener on component unmount or visibility change
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, [dropdownVisible]);

  const handleDropdownToggle = (e, conversationID) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setDropdownVisible((prev) => {
      const isTogglingOff = prev === conversationID;
      if (!isTogglingOff) {
        ignoreClickRef.current = true; // Set flag to prevent immediate closing on outside click
      }
      return isTogglingOff ? null : conversationID; // Toggle dropdown visibility
    });
  };

  // Reset selectedConversation to null if clicked outside the conversation list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (conversationListRef.current && !conversationListRef.current.contains(event.target)) {
        setSelectedConversation(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const iconSize = 24;
  const sidebarWidth = expanded ? 'w-60' : 'w-16';

  const containerRef = useRef(null);
  const [visibleItems, setVisibleItems] = useState([]);
  // Calculate the initial visible items based on the length of conversations
  useEffect(() => {
    const initialVisibleItems = [];
    const visibleCount = Math.min(conversations.length, 9); // Max 9 visible items
    for (let i = 0; i < visibleCount; i++) {
      initialVisibleItems.push(i);
    }
    setVisibleItems(initialVisibleItems);
    // console.log('Initial Visible Conversations:', initialVisibleItems);
  }, [conversations.length]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    const currentlyVisible = [];
    container.querySelectorAll('.conversation-item').forEach((item, index) => {
      const itemTop = item.offsetTop - container.offsetTop; // Adjust for container's offset
      const itemBottom = itemTop + item.offsetHeight;

      if (itemBottom > containerTop && itemTop < containerBottom) {
        currentlyVisible.push(index);
      }
    });

    setVisibleItems(currentlyVisible);
    // console.log('Visible Conversations:', currentlyVisible);
  };

  return (
    <aside className={`fixed top-0 left-0 h-screen ${sidebarWidth} transition-all duration-300 z-50`}>
      <nav className="h-full flex flex-col bg-[#EEEFF2] border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <NavLink to="/home" onClick={handleNewConversation}>
            <img
              src={logo}
              alt="logo"
              className={`overflow-hidden transition-all ${expanded ? 'w-32' : 'w-8'}`}
            />
          </NavLink>
          <button
            onClick={() => setExpanded((curr) => !curr)}
            title={expanded ? 'Close Sidebar' : 'Open Sidebar'}
            className={`p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100 ${!expanded && 'mx-auto'}`}
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>
        <ul className="flex-1 pl-3 pt-1 mt-1">
          <li
            className={`flex items-center gap-4 text-gray-800 hover:bg-gray-300 p-2 rounded cursor-pointer ${!expanded && 'justify-center'
              }`}
            onClick={handleNewConversation}
            title="New Investigation"
          >
            <AiOutlinePlusCircle size={iconSize} />
            {expanded && <span>New Investigation</span>}
          </li>
          <NavLink
            to="/history"
            className={`flex items-center gap-4 text-gray-800 hover:bg-gray-300 p-2 rounded ${!expanded && 'justify-center'
              }`}
            title="All Investigations"
          >
            <PiFlask size={iconSize} />
            {expanded && <span>All Investigations</span>}
          </NavLink>
          <NavLink
            to="/edited"
            className={`flex items-center gap-4 text-gray-800 hover:bg-gray-300 p-2 rounded ${!expanded && 'justify-center'
              }`}
            title="Edited Molecules"
          >
            <SiMoleculer size={iconSize} />
            {expanded && <span>Edited Molecules</span>}
          </NavLink>
          <div ref={conversationListRef}>
            {expanded && (
              <div className='pt-2 mt-2'>
                <span className="text-gray-600 text-sm ">History</span>

                <div className="flex flex-col gap-2 mt-4 custom-scrollbar overflow-y-auto" style={{ maxHeight: '400px', minHeight: '176px' }} ref={containerRef} onScroll={handleScroll}>
                  {conversations.map((conversation, index) => {
                    // const summary = conversation.Summary && conversation.Summary.length > 0 ? conversation.Summary[0] : {};
                    // const truncatedID = summary.history_title ? summary.history_title.substring(0, 16) : 'New Chat';
                    // const QueryName = conversation.queries?.[0]?.QueryName || '';
                    // const truncatedID = QueryName ? QueryName.substring(0, 24) : 'New Chat';

                    const truncatedID = conversation.Summary?.[0]?.history_title || conversation.queries?.[0]?.QueryName || 'New Chat';

                    const isEditing = editingConversation?.conversationID === conversation.conversationID;
                    // Determine the position of the current index in visibleItems
                    const isTopHalf = conversations.length >= 3 && visibleItems.includes(index) && visibleItems.indexOf(index) < Math.ceil(visibleItems.length / 2);
                    const isBottomHalf = conversations.length >= 3 && visibleItems.includes(index) && visibleItems.indexOf(index) >= Math.ceil(visibleItems.length / 2);
                    return (
                      <div
                        key={conversation.conversationID}
                        className={`conversation-item flex items-center justify-between gap-4 text-gray-800 py-2 pl-2 hover:bg-gray-300 rounded 
                          ${selectedConversation === conversation.conversationID ? 'bg-gray-300' : 'hover:bg-gray-300'}`}
                      // onClick={() => {
                      //   setSelectedConversation(conversation.conversationID);
                      //   console.log('Selected Conversation ID:', conversation.conversationID); // Log to console
                      // }}
                      >
                        <NavLink
                          to={`/c/${conversation.conversationID}`}
                          // className="flex-1 truncate "
                          className={({ isActive }) => {
                            // Add bg-gray-300 when active
                            const navLinkClass = `flex-1 truncate ${isActive ? 'bg-gray-300' : ''}`;

                            // Pass the active state to parent div to set background
                            if (isActive) {
                              setSelectedConversation(conversation.conversationID);
                            }
                            return navLinkClass;
                          }}
                          onClick={() => handleExistedConv_ID(conversation.conversationID)}
                        >
                          {/* {truncatedID ? `${truncatedID}...` : 'New Chat'} */}
                          {isEditing ? (
                            <div className="ml-2 flex items-center w-full border border-gray-300 rounded-lg shadow-sm " ref={editContainerRef}>
                              <input
                                type="text"
                                value={newHistoryTitle}
                                onChange={(e) => setNewHistoryTitle(e.target.value)}
                                onKeyDown={handleKeyDown}
                                ref={inputRef}
                                className=" text-gray-700 bg-white placeholder-gray-500 bg-transparent border-none outline-none flex-1 rounded-md p-2 "
                                placeholder="Enter title"
                              />
                              {/* <button
                                className="ml-2 p-1 text-green-500 hover:text-green-700 transition duration-150 ease-in-out transform hover:scale-110"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSave();
                                }}
                                title="Save"
                              >
                                <FiCheck size={18} />
                              </button>
                              <button
                                className="ml-1 p-1 text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out transform hover:scale-110"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCancelEdit();
                                }}
                                title="Cancel"
                              >
                                <FiX size={18} />
                              </button> */}
                            </div>
                          ) : (
                            `${truncatedID}`
                          )}

                        </NavLink>
                        {/* <button
                        className="p-2 text-[#FF9783] cursor-pointer"
                        title='Delete'
                        onClick={(e) => confirmDelete(e, conversation)}
                      >
                        <FiTrash2 />
                      </button> */}
                        <div className="relative">
                          <div>

                            <button
                              className=" text-gray-700 hover:text-gray-900 pr-2"
                              onClick={(e) => handleDropdownToggle(e, conversation.conversationID)}
                            >
                              <MoreVertical size={18} />
                            </button>
                          </div>
                          {dropdownVisible === conversation.conversationID && (
                            <div
                              className={`absolute right-0 mt-2 mr-0 w-32 p-1 bg-gray-300 border border-gray-100 rounded-2xl shadow-lg z-10 ${isTopHalf ? 'top-1/2' : isBottomHalf ? '-top-24' : ''}
                                  }`}
                              ref={dropdownRef}
                            >
                              <button
                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 hover:rounded-xl flex items-center gap-2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openEditPopup(conversation);
                                  setDropdownVisible(null);
                                }}
                              >
                                <MdEdit /> Rename
                              </button>
                              <button
                                className="block w-full text-left px-4 py-2 text-red-500 hover:bg-gray-100 hover:rounded-xl flex items-center gap-2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  confirmDelete(e, conversation);
                                  setDropdownVisible(null);
                                }}
                              >
                                <FiTrash2 /> Delete
                              </button>
                            </div>
                          )}

                        </div>
                      </div>
                    );
                  })}
                </div>
                {conversations.length >= 4 && (
                  <NavLink to="/history" className="text-blue-500 text-sm">View all</NavLink>
                )}
              </div>

            )}
          </div>
        </ul>
        <div className="relative p-3 border-t flex">
          <img
            onClick={() => setExpanded((curr) => !curr)}
            src={`https://ui-avatars.com/api/?name=${username || 'Guest'}`}
            alt="User Avatar"
            className="w-10 h-10 rounded-md mx-auto cursor-pointer"
          />
          <div
            className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}
          >
            {expanded && (
              <>
                <div className="leading-4">
                  <h4 className="font-semibold">{username || 'Guest'}</h4>
                  <span className="text-xs text-gray-600">{email || 'guest@example.com'}</span>
                </div>
                <button onClick={() => setShowOptions(!showOptions)}>
                  <MoreVertical size={20} />
                </button>
              </>
            )}
          </div>
          {showOptions && expanded && (
            <div className="absolute bottom-14 left-0 w-full bg-white border rounded shadow-lg">
              <NavLink to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-300 flex items-center"
                onClick={() => setShowOptions(false)}>
                <CgProfile size={iconSize} className="inline-block mr-2" />
                Profile
              </NavLink>
              {/* <NavLink to="/settings" className="block px-4 py-2 text-gray-800 hover:bg-gray-300">
                <HiOutlineCog6Tooth size={iconSize} className="inline-block mr-2" />
                Settings
              </NavLink> */}
              <NavLink
                to="/logout"
                onClick={resetConv_ID}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-300 flex items-center"
              >
                <IoPower size={iconSize} className="inline-block mr-2" />
                LogOut
              </NavLink>
            </div>
          )}
        </div>
      </nav>

      {/* Delete Confirmation Popup */}
      {deletingConversation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete this investigation?</h3>
            <div className="flex justify-end">

              <button
                className="p-2 bg-gray-200 rounded-md mr-2"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="p-2 bg-red-500 text-white rounded-md"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
};

export default Sidebar;