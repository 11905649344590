import React, { useState } from 'react';
// import logo from '../../logo.png';
import axios from 'axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setLoading(true);

    axios.post(`${BACKEND_URL}/auth/forgot-password`, { email })
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          // alert("check your email for reset password link")
          setMessage('A password reset link has been sent to your email address. ( Please check your inbox. If not found, check your spam or junk folder )');
        } else {
          setError('Email address not found.');
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        setError('An error occurred. Please try again.');
      });
  };

  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          {/* <div className="flex justify-center mb-8">
            <img src={logo} alt="Covaln Logo" className="h-12" />
          </div> */}
          <h2 className="text-2xl font-bold text-center text-gray-700 mb-4">Forgot Password</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="example@covaln.com"
                className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {message && <p className="text-green-500 text-sm mb-4">{message}</p>}
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <div className="flex items-center justify-center">
              <button type="submit" className="bg-white text-[#FE4202] font-bold py-2 px-4 border border-[#FE4202] rounded focus:outline-none focus:shadow-outline hover:bg-[#FE4202] hover:text-white transition-colors duration-300" disabled={loading}>
                {loading ? 'Sending...' : 'Send Reset Link'}
              </button>
            </div>
          </form>
        </div>
        <div className="absolute bottom-0 w-full text-center pb-4">
          <a href="#/" className="text-gray-500 text-sm hover:underline">Terms of Use</a>,
          <a href="#/" className="text-gray-500 text-sm hover:underline"> Privacy Policy</a> and
          <a href="#/" className="text-gray-500 text-sm hover:underline"> Cookies Policy</a>.
        </div>
      </div>
    </>
  )
}

export default ForgotPassword