import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineDownload } from 'react-icons/ai';
import ExcelJS from 'exceljs';

const DockDataPopup = ({ dockData, dockSmile, selectedPdbId, onClose }) => {
// console.log(dockData)
    // Function to handle download
    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Docking Data');

        // Define columns
        worksheet.columns = [
            { header: 'Model', key: 'model', width: 20 },
            { header: 'Binding Affinity (kcal/mol)', key: 'binding_affinity', width: 30 },
            { header: 'RMSD Lower Bound', key: 'rmsd_lb', width: 20 },
            { header: 'RMSD Upper Bound', key: 'rmsd_ub', width: 20 },
        ];

        // Add header styles
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        // Add the docking data to the worksheet
        dockData.forEach((data) => {
            worksheet.addRow({
                model: data.model,
                binding_affinity: data['binding affinity'],
                rmsd_lb: data['rmsd l.b.'],
                rmsd_ub: data['rmsd u.b.'],
            });
        });

        // Generate Excel buffer and trigger download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'docking_data.xlsx';
        link.click();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white p-4 rounded shadow-md">
                {/* Close icon in top-right corner */}
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-red-500 hover:text-gray-700 transition"
                >
                    <AiOutlineClose className="w-4 h-4" title='close' />
                </button>

                <div className="flex justify-center mb-2 text-black overflow-y-auto scrollbar-hide max-w-[98%]">
                    <p className="text-lg font-bold flex flex-wrap mb-2 ">
                        Docked
                        <div className="overflow-x-auto max-w-[250px] whitespace-nowrap custom-scrollbar mx-1">
                            {dockSmile}
                        </div>
                        against structure {selectedPdbId}
                    </p>
                </div>

                <table className="min-w-full border">
                    <thead>
                        <tr className="bg-[#EEEFF2]">
                            <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                Model
                            </th>
                            <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                Binding Affinity (kcal/mol)
                            </th>
                            <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                RMSD LOWER BOUND
                            </th>
                            <th className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                                RMSD UPPER BOUND
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dockData.map((data) => (
                            <tr key={data.model}>
                                <td className="border px-4 py-2 text-sm text-gray-700">{data.model}</td>
                                <td className="border px-4 py-2 text-sm text-gray-700">{data['binding affinity']}</td>
                                <td className="border px-4 py-2 text-sm text-gray-700">{data['rmsd l.b.']}</td>
                                <td className="border px-4 py-2 text-sm text-gray-700">{data['rmsd u.b.']}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-end items-center mb-4 py-2">
                    <button
                        className="px-3 py-1 ml-3 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                        onClick={handleDownload}
                    >
                        <AiOutlineDownload className="w-5 h-5 text-gray-700" title='Download' />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DockDataPopup;