// import React, { useState, useEffect } from 'react';
// import TableViewProtein from './TableViewProtein';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProteins, fetchRelations, fetchProteinSequence } from '../../redux/Actions/action';

// const PredictProteinsInterface = (data) => {
//     const [relatedProteins, setRelatedProteins] = useState([]);
//     const [testingData, setTestingData] = useState([]);
//     const [tableData, setTableData] = useState([]);
//     const [error, setError] = useState(null);

//     const ProteinsData = useSelector((state) => state.Proteins);
//     const ProteinSeq = useSelector((state)=> state.ProteinSeq);
//     const RelationsData = useSelector((state) => state.Relations);
//     const dispatch = useDispatch();
    
//     useEffect(() => {
//         dispatch(fetchProteins());
//         dispatch(fetchRelations());
//         dispatch(fetchProteinSequence());
//     }, [dispatch]);
    
//     const truncateSequence = (sequence, length = 10) => {
//         return sequence.length > length ? `${sequence.substring(0, length)}...` : sequence;
//     };

//     // const selectedDisease = "D012707";  // Example disease ID
//     const selectedDisease = data.data.DISEASE;
//     useEffect(() => {
//         if (ProteinsData.length > 0 && RelationsData.length > 0 && ProteinSeq.length > 0) {
//             const relatedProteins = RelationsData.filter(relation => relation.TAIL === selectedDisease && relation.RELATION === "PROTEIN_DISEASE_ASSOCIATION"
//             ).map(proteinRelation => {
//                 const testItem = ProteinSeq.find(testItem => testItem.PROTEIN === proteinRelation.HEAD);
//                 const protein = ProteinsData.find(protein => protein.PROTEIN === proteinRelation.HEAD);

//                 return {
//                     ...proteinRelation,
//                     proteinId: proteinRelation.HEAD,
//                     sequence: testItem?.SEQUENCE || 'N/A',
//                     symbol: protein?.NAME,
//                     Name: protein?.FULL_NAME,
//                     structure: 'View'

//                 };
//             });

//             setRelatedProteins(relatedProteins);
//             // console.log(relatedProteins)

//             const formattedData = relatedProteins.map(item => ({
//                 proteinId: item.proteinId,
//                 sequence: item.sequence || 'N/A',
//                 symbol: item.symbol || 'N/A',
//                 targetName: item.Name || 'N/A',
//                 structure: item.structure || 'N/A'
//             }));
//             setTableData(formattedData);
//         }
//     }, [ProteinsData, RelationsData, testingData, selectedDisease]);
//     // console.log(relatedProteins)
//     // console.log(tableData)

//     return (
//         <>
//             <div className=" p-4">
//                 {error ? (
//                     <div className="text-xl font-bold mb-4 text-gray-400">{error} <span className=' pl-2 text-[#494949]'> {data.data.DISEASE} </span></div>
//                 ) : (<>
//                     <h2 className="text-xl font-bold mb-4 text-[#494949]">Proteins for {data.data.NAME} ({data.data.DISEASE})</h2>

//                     <div className="h-full py-4">
//                         <TableViewProtein data={tableData} selectable={false} argument={data} />
//                     </div>
//                 </>
//                 )}
//             </div>

//         </>);
// };

// export default PredictProteinsInterface

import React, { useState, useEffect } from 'react';
import TableViewProtein from './TableViewProtein';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const PredictProteinsInterface = ( data ) => {
    const [tableData, setTableData] = useState([]);
    const [error, setError] = useState(null);

    // Fetch the related proteins based on the selected disease
    useEffect(() => {
        const fetchRelatedProteins = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/relations/proteins/${data.data.DISEASE}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch related proteins');
                }
                const result = await response.json();
                // console.log(result)
                setTableData(result);
            } catch (error) {
                setError(error.message);
            }
        };
        
        fetchRelatedProteins();
    }, [data.data.DISEASE]);
    
    return (
        <div className="p-4">
            {error ? (
                <div className="text-xl font-bold mb-4 text-gray-400">
                    {error} <span className="pl-2 text-[#494949]">{data.data.DISEASE}</span>
                </div>
            ) : (
                <>
                    <h2 className="text-xl font-bold mb-4 text-[#494949]">
                        Proteins for {data.data.NAME} ({data.data.DISEASE})
                    </h2>

                    <div className="h-full py-4">
                        <TableViewProtein data={tableData} selectable={false} argument={data} />
                    </div>
                </>
            )}
        </div>
    );
};

export default PredictProteinsInterface;