import React, { useEffect, useRef, useState } from 'react';
import * as $3Dmol from '3dmol';

const Sdf = ({ sdf }) => {
    const containerRef = useRef(null);
    const glviewer = useRef(null);
    const [color, setColor] = useState('white');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const element = containerRef.current;

        if (!element || !sdf || typeof sdf !== 'string') {
            setErrorMessage('No structure available for this drug');
            return;
        }

        // Ensure the element has valid dimensions
        if (element.clientWidth === 0 || element.clientHeight === 0) {
            setErrorMessage('No structure available for this drug');
            return;
        }

        // Reset the error message if everything is fine
        setErrorMessage('');

        // Initialize the viewer
        glviewer.current = $3Dmol.createViewer(element, { backgroundColor: 'black' });
        glviewer.current.addModel(sdf, 'sdf'); // Load SDF data
        
        // Apply initial styles
        glviewer.current.setStyle({}, { stick: { color: 'white' } });
        glviewer.current.zoomTo(); // Set camera
        glviewer.current.render(); // Render scene
        glviewer.current.zoom(1.2, 1000); // Slight zoom

        return () => {
            glviewer.current.removeAllModels();
            glviewer.current = null;
        };
    }, [sdf]);

    const updateStyle = (style) => {
        if (glviewer.current) {
            const styles = {
                stick: { stick: {} },
                line: { line: {} },
                cross: { cross: {} },
                sphere: { sphere: {} },
                cartoon: { cartoon: {} }
            };
            glviewer.current.setStyle({}, styles[style]);
            glviewer.current.render();
        }
    };

    const updateColor = (newColor) => {
        setColor(newColor);
        if (glviewer.current) {
            glviewer.current.setStyle({}, { stick: { color: newColor } });
            glviewer.current.render();
        }
    };

    const applySnakeColoring = () => {
        if (glviewer.current) {
            const colorPattern = (atom) => atom.resi % 2 === 0 ? 'red' : 'yellow';
            glviewer.current.setStyle({}, { stick: { colorfunc: colorPattern } });
            glviewer.current.render();
        }
    };

    return (
        <>
            {errorMessage ? (
                <div className="mt-4">
                    <p>{errorMessage}</p>
                </div>
            ) : (
                <>
                    <div ref={containerRef} className="w-full h-[400px] relative" />
                    <div className="mt-4 space-x-2">
                        <button
                            onClick={() => updateStyle('stick')}
                            className="px-4 py-2 text-black rounded hover:bg-orange-500"
                        >
                            Stick
                        </button>
                        <button
                            onClick={() => updateStyle('line')}
                            className="px-4 py-2 text-black rounded hover:bg-orange-500"
                        >
                            Line
                        </button>
                        <button
                            onClick={() => updateStyle('cross')}
                            className="px-4 py-2 text-black rounded hover:bg-orange-500"
                        >
                            Cross
                        </button>
                        <button
                            onClick={() => updateStyle('sphere')}
                            className="px-4 py-2 text-black rounded hover:bg-orange-500"
                        >
                            Sphere
                        </button>
                        <button
                            onClick={applySnakeColoring}
                            className="px-4 py-2 text-black rounded hover:bg-orange-500"
                        >
                            Color Snake
                        </button>
                    </div>
                    <div className="mt-4">
                        <label className="mr-2">Select Color: </label>
                        <select
                            value={color}
                            onChange={(e) => updateColor(e.target.value)}
                            className="px-2 py-1 border border-gray-300 rounded"
                        >
                            <option value="white">White</option>
                            <option value="green">Green</option>
                            <option value="blue">Blue</option>
                            <option value="red">Red</option>
                            <option value="yellow">Yellow</option>
                            {/* <option value="spectrum">spectrum</option> */}
                        </select>
                    </div>
                </>
            )}
        </>
    );
};

export default Sdf;