import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const ConfirmPage = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    axios.post(`${BACKEND_URL}/auth/verify-code`, { email, confirmationCode })
      .then(response => {
        setLoading(false);
        if (response.data.status) {
          navigate('/login');
        } else {
          setError(response.data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        setError('An error occurred. Please try again.');
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="confirmationCode" className="block text-gray-700 text-sm font-bold mb-2">Confirmation Code</label>
            <input
              type="text"
              id="confirmationCode"
              name="confirmationCode"
              placeholder="Enter the code sent to your email"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              required
            />
          </div>
          {error && <p className="text-[#FE4202] text-sm mb-4">{error}</p>}
          <div className="flex items-center justify-center">
            <button type="submit" className="bg-white text-[#FE4202] font-bold py-2 px-4 border border-[#FE4202] rounded focus:outline-none focus:shadow-outline hover:bg-[#FE4202] hover:text-white transition-colors duration-300" disabled={loading}>
              {loading ? 'Verifying...' : 'Verify'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmPage;