import React, { useContext, useState } from 'react';
import logo from '../../Assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthContext } from '../../AuthContext';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  axios.defaults.withCredentials = true;
  const { setIsAuthenticated } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    axios.post(`${BACKEND_URL}/auth/login`, {
      email: email,
      password: password
    })
      .then(response => {
        setLoading(false);
        // console.log(response);
        if (response.data.status) {
          // Save the token to local storage
          // localStorage.setItem('token', response.data.token);
          setIsAuthenticated(true);
          navigate('/home', { state: { username: response.data.username } });
        } else {
          setError('Invalid email or password');
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setError('An error occurred. Please try again.');
        alert('Entered wrong credentials')
      });
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Covaln Logo" className="h-12" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="example@covaln.com"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6 relative">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="********"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute right-3 bottom-1 transform -translate-y-1/2 cursor-pointer text-gray-400"
              title={showPassword ? 'Hide password' : 'Show password'}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <div className="flex items-center justify-center">
            <button type="submit" className="bg-white text-[#FE4202] font-bold py-2 px-4 border border-[#FE4202] rounded focus:outline-none focus:shadow-outline hover:bg-[#FE4202] hover:text-white transition-colors duration-300" disabled={loading}>
              {loading ? 'Logging In...' : 'Login'}
            </button>
          </div>
          <div className="text-center mt-4">
            <p className="text-gray-600 text-sm">Don't have an account? <Link to="/signup" className="text-[#FE4202] hover:underline">Create Account</Link></p>
          </div>
          <div className="text-center">
            <a href="/forgotPassword" className="text-gray-500 text-sm hover:underline">Forgot Password?</a>
          </div>
        </form>
      </div>
      <div className="absolute bottom-0 w-full text-center pb-4">
        <a href="#/" className="text-gray-500 text-sm hover:underline">Terms of Use</a>,
        <a href="#/" className="text-gray-500 text-sm hover:underline"> Privacy Policy</a> and
        <a href="#/" className="text-gray-500 text-sm hover:underline"> Cookies Policy</a>.
      </div>
    </div>
  );
}

export default LoginPage;