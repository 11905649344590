import React from 'react';
import logo from '../../Assets/logo.png';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Covaln Logo" className="h-12" />
        </div>
        <h2 className="text-2xl font-medium mb-2">Welcome!</h2>
        <p className="text-gray-600 mb-6">
          Log in or sign up to start your drug discovery journey - responses, upload files and images, and more.
        </p>
        <div className="flex flex-col items-center space-y-4">
          <button
            type="button"
            onClick={() => navigate('/login')}
            className="bg-white text-[#FE4202] font-bold py-2 px-4 border border-[#FE4202] rounded-full focus:outline-none focus:shadow-outline hover:bg-[#FE4202] hover:text-white transition-colors duration-300 w-3/4"
          >
            Login
          </button>
          <button
            type="button"
            onClick={() => navigate('/signup')}
            className="bg-gray-200 text-gray-700 font-bold py-2 px-4 border border-gray-300 rounded-full focus:outline-none focus:shadow-outline hover:bg-gray-300 transition-colors duration-300 w-3/4"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;