import React, { useEffect, useState } from 'react';
import StructureEditor from 'react-ocl/lib-cjs/components/StructureEditor.js';
import OCL from 'openchemlib';
import { GrNext, GrPrevious } from "react-icons/gr";
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const MoleculeEditorPopup = ({ smilesList, onSaveAll, onClose }) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [savedCount, setSavedCount] = useState(0); // Tracks the count of saved molecules
  const [currentIndex, setCurrentIndex] = useState(0);
  const [molecules, setMolecules] = useState(
    Array.isArray(smilesList)
      ? smilesList.map((smiles) => {
          const molecule = OCL.Molecule.fromSmiles(smiles);
          return {
            initialSmiles: smiles,
            currentSmiles: smiles,
            initialMolfile: molecule.toMolfile(),
            currentMolfile: molecule.toMolfile(),
            initialIDCode: molecule.getIDCode(),
            currentIDCode: molecule.getIDCode(),
            edited: false,
          };
        })
      : []
  );
  const [currentSmiles, setCurrentSmiles] = useState(smilesList[0] || '');
  const [smilesArray, setSmilesArray] = useState(Array.isArray(smilesList) ? smilesList : []);

  useEffect(() => {
    console.log('Initial SMILES List:', smilesList);
  }, [smilesList]);

  const handleMolfileChange = (index, molfile) => {
    const updatedMolecules = [...molecules];
    const molecule = OCL.Molecule.fromMolfile(molfile);
    const newIDCode = molecule.getIDCode();
    const newSmiles = molecule.toSmiles();

    const isEdited = newIDCode !== updatedMolecules[index].initialIDCode;

    updatedMolecules[index] = {
      ...updatedMolecules[index],
      currentMolfile: molfile,
      currentIDCode: newIDCode,
      currentSmiles: newSmiles,
      edited: isEdited,
    };

    setMolecules(updatedMolecules);
  };

  const handleNext = () => {
    const newIndex = Math.min(currentIndex + 1, smilesArray.length - 1);
    setCurrentIndex(newIndex);
    setCurrentSmiles(molecules[newIndex].currentSmiles);
  };

  const handlePrevious = () => {
    const newIndex = Math.max(currentIndex - 1, 0);
    setCurrentIndex(newIndex);
    setCurrentSmiles(molecules[newIndex].currentSmiles);
  };

  const handleSaveAll = () => {
    const editedMolecules = molecules.filter((molecule) => molecule.edited);

    if (editedMolecules.length === 0) {
      setSnackbarMessage('No molecules were edited.');
      setSnackbarOpen(true);
      return;
    }

    if (editedMolecules.some((molecule) => molecule.currentSmiles.trim() === '')) {
      setSnackbarMessage('SMILES cannot be empty.');
      setSnackbarOpen(true);
      return;
    }

    if (typeof onSaveAll !== 'function') {
      console.error('onSaveAll is not a function');
      return;
    }

    // Pass back the edited molecules with initial and current smiles
    onSaveAll(
      editedMolecules.map((molecule) => ({
        initialSmiles: molecule.initialSmiles,
        currentSmiles: molecule.currentSmiles,
      }))
    );

    // Set the count of saved molecules
    setSavedCount(editedMolecules.length);
    setSnackbarMessage(`${editedMolecules.length} molecule(s) saved successfully.`);

    // Log the save action
    console.log(`${editedMolecules.length} molecule(s) saved.`);

    // Open the snackbar
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const isSingleSmiles = smilesArray.length === 1;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg w-5/6 max-w-4xl">
        <h3 className="text-2xl font-semibold mb-4">
          {isSingleSmiles
            ? 'Edit Molecule'
            : `Edit Molecule ${currentIndex + 1} of ${molecules.length}`}
        </h3>
        <p className="break-words py-2">
          <strong>SMILES:</strong> {molecules[currentIndex].currentSmiles}
        </p>
        <div className="relative">
          <div className="overflow-hidden">
            {!isSingleSmiles ? (
              <div
                className="flex transition-transform duration-300"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {molecules.map((moleculeObj, index) => (
                  <div
                    key={index}
                    style={{ width: '100%', height: '400px', flexShrink: 0 }}
                  >
                    <StructureEditor
                      initialMolfile={moleculeObj.currentMolfile}
                      onChange={(molfile) => handleMolfileChange(index, molfile)}
                      width="100%"
                      height="100%"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <StructureEditor
                initialMolfile={molecules[currentIndex].currentMolfile}
                onChange={(molfile) => handleMolfileChange(currentIndex, molfile)}
                width="100%"
                height="400px"
              />
            )}
          </div>
        </div>
        <div className="flex justify-between mt-4">
          {!isSingleSmiles && (
            <>
              <button
                onClick={handlePrevious}
                className={`px-4 py-2 bg-[#EEEFF2] rounded mr-2 flex items-center justify-center hover:bg-[#DDE0E5] transition duration-200 ${
                  currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={currentIndex === 0}
                aria-label="Previous molecule"
              >
                <GrPrevious className="w-5 h-5 text-gray-700" title="Previous" />
              </button>
              <button
                onClick={handleNext}
                className={`px-4 py-2 bg-[#EEEFF2] rounded mr-2 flex items-center justify-center hover:bg-[#DDE0E5] transition duration-200 ${
                  currentIndex === smilesArray.length - 1
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                }`}
                disabled={currentIndex === smilesArray.length - 1}
                aria-label="Next molecule"
              >
                <GrNext className="w-5 h-5 text-gray-700" title="Next" />
              </button>
            </>
          )}
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={handleSaveAll}
            className="px-6 py-2 bg-[#4CAF50] text-white rounded mr-4 flex items-center hover:bg-[#45A049] transition duration-200"
            aria-label={isSingleSmiles ? "Save molecule" : "Save all molecules"}
          >
            {isSingleSmiles ? "Save" : "Save All"}
          </button>
          <button
            onClick={onClose}
            className="px-6 py-2 bg-[#f44336] text-white rounded flex items-center hover:bg-[#da190b] transition duration-200"
            aria-label="Close editor"
          >
            Close
          </button>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          elevation={6}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default MoleculeEditorPopup;