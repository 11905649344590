import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const UserDetailPage = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/auth/usersList`);
                const users = response.data;
                const userDetail = users.find(user => user._id === userId);
                setUser(userDetail);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <div>User not found.</div>;
    }

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">User Admin</h1>
            <div className="bg-gray-100 p-4 rounded-lg mb-4">
                <p><strong>User ID:</strong> {user._id}</p>
                <p><strong>Username:</strong> {user.username}</p>
                <p><strong>Status:</strong> {user.isConfirmed ? 'Active' : 'Inactive'}</p>
            </div>
            <h2 className="text-xl font-bold mb-2">Conversations</h2>
            <div className="bg-white shadow-md rounded-lg p-4">
                {user.conversations.length > 0 ? (
                    user.conversations.map((conversation, index) => (
                        <div key={index} className="mb-4 border p-4 rounded-lg">
                            <h3 className="font-bold">Query Name: {conversation.queries[0]?.SimpleQuery}</h3>
                            <p><strong>Query:</strong> {conversation.queries[0]?.response}</p>
                        </div>
                    ))
                ) : (
                    <p>No conversations found.</p>
                )}
            </div>
        </div>
    );
};

export default UserDetailPage;