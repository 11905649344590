import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

// Set app element for react-modal
Modal.setAppElement('#root');

const DiseaseDetailsPopup = ({ diseaseId, onClose }) => {
    const [isOpen, setIsOpen] = useState(true); // State to control modal open/close
    const [diseaseDetails, setDiseaseDetails] = useState(null);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/disease/${diseaseId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch disease details');
                }
                const data = await response.json();
                setDiseaseDetails(data);
            } catch (error) {
                console.error('Error fetching disease details:', error);
            }
        };

        fetchData();
    }, [diseaseId]);

    // Handle modal close
    const closeModal = () => {
        setIsOpen(false);
        onClose(); // Callback to parent component to handle modal close
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            contentLabel="Disease Details"
        >
            <div className="relative bg-white p-6 rounded-lg w-full sm:w-4/5 md:w-2/3 lg:w-1/2 h-auto max-h-[80vh]">
                {/* Close Button */}
                <button
                    onClick={closeModal}
                    className="absolute top-3 right-3 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600 hover:bg-red-200 transition duration-200"
                    aria-label="Close Modal"
                >
                    &times;
                </button>

                {/* Title */}
                <h2 className="px-4 text-xl md:text-2xl font-semibold text-gray-800 mb-4">
                    Disease Details</h2>
                <div className="overflow-y-auto custom-scrollbar max-h-[70vh] ">

                    {diseaseDetails ? (
                        <div>
                            <table className="w-full border-collapse">
                                <tbody>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Disease ID</td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{diseaseDetails.DISEASE}</td>
                                    </tr>
                                    <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Name</td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{diseaseDetails.NAME}</td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Type</td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{diseaseDetails.TYPE}</td>
                                    </tr>
                                    <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Disease Supergroup</td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">
                                            {isNaN(diseaseDetails.DISEASE_SUPERGRP) ? 'Not populated' : diseaseDetails.DISEASE_SUPERGRP}
                                        </td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Index</td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{diseaseDetails.INDEX}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>Loading disease details...</p>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default DiseaseDetailsPopup;