// clipboardUtils.js
export const handleCopyToClipboard = (smiles, setSnackbarMessage, setOpen) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(smiles)
            .then(() => {
                setSnackbarMessage('SMILES copied to clipboard!');
                setOpen(true);
            })
            .catch((err) => {
                setSnackbarMessage('Failed to copy SMILES to clipboard.');
                setOpen(true);
            });
    } else {
        const textarea = document.createElement('textarea');
        textarea.value = smiles;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            setSnackbarMessage('SMILES copied to clipboard!');
        } catch (err) {
            setSnackbarMessage('Failed to copy SMILES to clipboard.');
        }
        document.body.removeChild(textarea);
        setOpen(true);
    }
};