import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setSelectedConversation } from '../../redux/Actions/action';

const EditedMolecules = () => {
  const [editedMolecules, setEditedMolecules] = useState([]);
  const [deletingMolecule, setDeletingMolecule] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   axios
  //     .get(`${BACKEND_URL}/auth/get-edited-molecules`, { withCredentials: true })
  //     .then((response) => {
  //       if (response.data.status) {
  //         setEditedMolecules(response.data.editedMolecules);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching edited molecules:', error);
  //     });
  // }, [BACKEND_URL]);
  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/auth/get-edited-molecules`, { withCredentials: true })
      .then(async (response) => {
        if (response.data.status) {
          const molecules = response.data.editedMolecules;

          // Extract unique conversation IDs
          const uniqueConversationIDs = [
            ...new Set(molecules.map((molecule) => molecule.conversationID)),
          ];
          // Fetch summaries for each unique conversation ID
          const summaries = await Promise.all(
            uniqueConversationIDs.map((id) =>
              axios
                .get(`${BACKEND_URL}/auth/get-summary-title/${id}`, {
                  withCredentials: true,
                })
                .then((res) => {
                  // console.log(`Summary for ${id}:`, res.data.summaryTitle);
                  return { id, title: res.data.summaryTitle };
                })
                .catch((error) => {
                  // console.error(`Error fetching summary for ${id}:`, error.message);
                  return { id, title: 'Title error' };
                })
            )
          );
          // Map summaries back to the molecules
          const moleculesWithSummaries = molecules.map((molecule) => {
            const summary = summaries.find(
              (s) => s.id === molecule.conversationID
            );
            return { ...molecule, summaryTitle: summary?.title || 'N/A' };
          });

          setEditedMolecules(moleculesWithSummaries);
        }
      })
      .catch((error) => {
        console.error('Error fetching edited molecules:', error);
      });
  }, [BACKEND_URL]);

  const dispatch = useDispatch();
  const handlePredict = (index) => {
    const selectedConversation = editedMolecules[index];
    const conv = editedMolecules[index]
    // console.log(selectedConversation)
    // Dispatch the action to store the conversation in Redux
    dispatch(setSelectedConversation(selectedConversation));
    if (location.pathname === '/profile') {
      navigate('/predictEditedMol', {
        state: { conversation: selectedConversation, from: '/profile' },
      });
    } else {
      navigate('/predictEditedMol', { state: { conversation: selectedConversation } });
    }
  };

  const confirmDelete = (event, conversation) => {
    event.stopPropagation();
    setDeletingMolecule(conversation);
  };

  const handleDelete = () => {
    axios
      .delete(
        `${BACKEND_URL}/auth/delete-edited-molecule/${deletingMolecule.conversationID}/${deletingMolecule._id}`,
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.status) {
          setEditedMolecules((prev) =>
            prev.filter((mol) => mol._id !== deletingMolecule._id)
          );
          setDeletingMolecule(null);
        }
      })
      .catch((error) => {
        console.error('Error deleting molecule:', error);
      });
  };


  return (
    <div className="bg-white rounded-xl p-3 shadow min-h-[200px]">
      <h1 className="text-2xl font-semibold mb-4">Edited Molecules</h1>
      <div>
        {editedMolecules.length === 0 ? (
          <p>No edited molecules found</p>
        ) : (
          <div >
            {Object.entries(
              editedMolecules.reduce((acc, molecule, idx) => {
                const { conversationID } = molecule;
                if (!acc[conversationID]) acc[conversationID] = [];
                acc[conversationID].push({ ...molecule, originalIndex: idx });
                return acc;
              }, {})
            ).map(([conversationID, queries]) => (
              <div
                key={conversationID}
                className="border p-4 my-2 rounded shadow-md cursor-pointer "
              >
                <h2 className="text-xl font-semibold mb-2 break-all">
                  {queries[0]?.summaryTitle || 'N/A'}</h2>
                {queries[0]?.summaryTitle === 'Title error' ? (
                  <p className="text-red-600 italic mb-4">This investigation is no longer available</p>
                ) : (
                  <p
                    className="text-gray-600 italic mb-4 cursor-pointer underline"
                    onClick={() => navigate(`/c/${conversationID}`)}
                  >
                    {/* Conversation ID: {conversationID} */}
                    View Investigation
                  </p>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {queries.map((query) => (
                    <div
                      key={query.originalIndex}
                      className="border-t pt-2 mt-2 bg-[#EEEFF2] shadow-md p-4 rounded-lg flex flex-col justify-between cursor-pointer"
                      onClick={() => handlePredict(query.originalIndex)}
                    >
                      <h3 className="text-lg font-medium">{query.queryName}</h3>
                      <p className="font-normal">
                        <span className="font-normal" style={{ color: '#FE4202' }}>
                          {query.molecules.length}{' '}
                        </span>
                        {query.molecules.length === 1 ? 'Molecule Edited' : 'Molecules Edited'}
                      </p>
                      {/* <p>Conversation summary title</p> */}
                      <div className="flex justify-between items-center">
                        <div className="flex space-x-2">
                          <button
                            className="py-2 pr-2 text-[#FF9783]"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent parent click
                              confirmDelete(e, query);
                            }}
                            title="Delete"
                          >
                            <FiTrash2 />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {deletingMolecule && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md">
            <p className="mb-4">Are you sure you want to delete this molecule?</p>
            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 bg-red-500 text-white rounded"
                onClick={handleDelete}
              >
                Yes
              </button>
              <button
                className="px-4 py-2 bg-gray-300 rounded"
                onClick={() => setDeletingMolecule(null)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditedMolecules;