import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AdmetPopup from './AdmetPopup';
import MoleculeEditorPopup from './MoleculeEditorPopup';
import { FiActivity, FiClipboard } from 'react-icons/fi';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { MdOutlineArrowBackIos } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import OCL from 'openchemlib/full';
import axios from 'axios'
import ExcelJS from 'exceljs';
import { AiOutlineDownload } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { fetchParameters, setSelectedConversation } from '../../redux/Actions/action';
import DockingPopup from './DockingPopup';
import DockDataPopup from './DockDataPopup';
import DockPopupInEdit from './DockPopupInEdit';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PredictEditedMol = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // const conv = location.state?.conversation;
    const conversation = useSelector((state) => state.selectedConversation);
    const [selectedMolecules, setSelectedMolecules] = useState([]);
    const [tableData, setTableData] = useState(conversation);
    const [selectedAdmet, setSelectedAdmet] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [currentMolecule, setCurrentMolecule] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(false); // Added loading state
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState({ smiles: '', svg: '' });
    const [selectedPdbId, setSelectedPdbId] = useState(null);
    const initialDrugsParameters = useSelector((state) => state.parameters.predictInitialDrugs);
    const dockParameters = useSelector((state) => state.parameters.dock);
    const [dockSmiles, setDockSmiles] = useState(null);
    const target = conversation.proteinID;
    const UserData = useSelector((state) => state.UserDetails);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentDockData, setCurrentDockData] = useState([]);
    const [currentDocksmile, setCurrentDockSmile] = useState([]);
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchParameters()); // Fetch parameters when the component mounts
    }, [dispatch]);

    const fetchTableData = async () => {
        // setLoading(true);
        const { conversationID, queryID } = location.state?.conversation || {};

        if (!conversationID || !queryID) {
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${BACKEND_URL}/auth/get-edited-molecules`, {
                params: {
                    conversationID,
                    queryID
                }
            });
            setTableData(response.data.editedMolecules[0]);
        } catch (err) {
            console.error('Error fetching edited molecules:', err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchTableData();
    }, [conversation]);

    const handleCheckboxChange = (molecule) => {
        setSelectedMolecules((prevSelected) =>
            prevSelected.includes(molecule)
                ? prevSelected.filter((item) => item !== molecule)
                : [...prevSelected, molecule]
        );
    };
    // const handleEditSmiles = (molecule) => {
    //     setCurrentMolecule(molecule);
    //     setEditorOpen(true);
    // };

    const handlePredict = async () => {
        if (selectedMolecules.length === 0) {
            setSnackbarMessage('Please select at least one molecule.');
            setOpen(true);
            return;
        }
        // Filter out ADMETPlot and COV from selectedMolecules (bcz of payLoad)
        const filteredMolecules = selectedMolecules.map(({ ADMETPlot, COV, ...rest }) => rest);

        const s_list = selectedMolecules.map((molecule) => molecule.editedMoleculeSMILES);
        const target = conversation.proteinID;

        const topk = initialDrugsParameters.topK || null;
        const probThreshold = initialDrugsParameters.probThreshold || 0.6;
        const payload = {
            s_list,
            num_cores: selectedMolecules.length,
            target,
            top_k: topk,
            prob_threshold: probThreshold,
            conversationID: conversation.conversationID,
            queryID: conversation.queryID,
            selectedMolecules: filteredMolecules
        };

        setLoading(true); // Set loading to true
        console.log('Payload being sent to backend:', payload); // Log the payload being sent
        try {
            // Call your backend (port 8000) instead of port 5000 directly
            const predictResponse = await fetch(`${BACKEND_URL}/auth/api/predict-drugs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!predictResponse.ok) {
                const errorResponse = await predictResponse.json();
                if (errorResponse.error === 'No drugs were found suitable for this target, try lowering the threshold value to get drugs') {
                    throw new Error(errorResponse.error);
                } else {
                    throw new Error(errorResponse.message || `HTTP error! Status: ${predictResponse.status}`);
                }
            }

            const dataFromBackend = await predictResponse.json();
            console.log('Data received from backend:', dataFromBackend); // Log backend data for confirmation

            // Handle case where no drugs were found
            if (dataFromBackend.error === 'No drugs were found suitable for this target, try lowering the threshold value to get drugs') {
                setSnackbarMessage('No suitable drugs found. Try lowering the threshold less than 0.5 and try again.');
                setOpen(true);
                return;
            }

            // Check if the molecules were updated successfully in the database
            if (dataFromBackend.success) {
                console.log('Molecules updated successfully:', dataFromBackend.message);
                setSnackbarMessage('Molecules updated successfully.');
                setOpen(true); // Optionally show success message on the UI
            } else {
                console.error('Error in backend response:', dataFromBackend.message);
                setSnackbarMessage('Error updating molecules: ' + dataFromBackend.message);
                setOpen(true); // Show error message on the UI
            }
            // Delay of 1 second
            setTimeout(() => {
                fetchTableData();
            }, 1000);
            setSelectedMolecules([])
        } catch (error) {
            // console.error('Error fetching predicted drugs:', error);
            // This part will handle network errors or failed fetches
            if (error.message.includes('Failed to fetch')) {
                setSnackbarMessage('Network error: Failed to fetch. Please check your connection or server.');
            } else {
                setSnackbarMessage(error.message + ' (Change parameters in settings page)');
            }
            setTimeout(() => {
                fetchTableData();
            }, 1000);
            setOpen(true); // Show error message on the UI
            setSelectedMolecules([])

        } finally {
            setLoading(false); // Set loading to false when done
        }
        setSelectedMolecules([])

    };

    const handleSaveMolecule = (newSmiles) => {
        setTableData((prevData) =>
            prevData.map((mol) =>
                mol.smiles === currentMolecule.editedMoleculeSMILES
                    ? { ...mol, editedMoleculeSMILES: newSmiles }
                    : mol
            )
        );
        setEditorOpen(false);
    };

    const handleSnackbarClose = () => {
        setOpen(false);
    };

    const handleCopyToClipboard = (smiles) => {
        // console.log(smiles);
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(smiles)
                .then(() => {
                    setSnackbarMessage('SMILES copied to clipboard!');
                    setOpen(true);
                })
                .catch((err) => {
                    // console.error('Failed to copy: ', err);
                    setSnackbarMessage('Failed to copy SMILES to clipboard.');
                    setOpen(true);
                });
        } else {
            const textarea = document.createElement('textarea');
            textarea.value = smiles;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                setSnackbarMessage('SMILE copied to clipboard!');
                // console.log('Fallback: Text copied!');
            } catch (err) {
                // console.error('Fallback copy failed: ', err);
                setSnackbarMessage('Failed to copy SMILES to clipboard.');
            }
            document.body.removeChild(textarea);
            setOpen(true);
        }
    };    

    if (!conversation) {
        return <p>No conversation data available</p>;
    }
    const handleBackNavigation = () => {
        // Check if the previous navigation passed the '/profile' path in location.state
        if (location.state?.from === '/profile') {
            navigate('/profile', { state: { activeTab: 'Edited Molecules' } });
        } else {
            // Default behavior is to navigate back in history
            navigate(-1);
        }
    };
    const convertSmilesToSvg = (smiles) => {
        try {
            const molecule = OCL.Molecule.fromSmiles(smiles);
            const svg = molecule.toSVG(300, 300);
            return btoa(svg); // Base64 encode the SVG
        } catch (error) {
            console.error('Error converting SMILES to SVG:', error);
            return '';
        }
    };

    const handleShowPopup = (smiles) => {
        const svg = convertSmilesToSvg(smiles);
        setPopupContent({ smiles, svg });
        setPopupOpen(true);
    };
    // Function to convert SVG to PNG
    const convertSvgToPng = (svgBase64) => {
        return new Promise((resolve, reject) => {
            const svgData = 'data:image/svg+xml;base64,' + svgBase64;
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const pngDataUrl = canvas.toDataURL('image/png');
                const pngBase64 = pngDataUrl.replace(/^data:image\/png;base64,/, '');
                resolve(pngBase64);
            };
            img.onerror = function (e) {
                reject(e);
            };
            img.src = svgData;
        });
    };
    // Function to handle download
    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Edited Molecules');

        const columns = [
            { header: '#', key: 'index', width: 5 },
            { header: 'MoleculeID', key: 'moleculeId', width: 15 },
            { header: 'Source SMILES', key: 'sourceSmiles', width: 30 },
            { header: 'Edited SMILES', key: 'editedSmiles', width: 30 },
            { header: 'Edited Molecule Structure', key: 'structure', width: 20 },
            { header: 'Admet Plot', key: 'admetPlot', width: 20 },
            { header: 'COV', key: 'cov', width: 10 },
        ];
        worksheet.columns = columns;

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        let rowIndex = 2;
        for (let i = 0; i < conversation.molecules.length; i++) {
            const molecule = conversation.molecules[i];
            const editedMoleculeSvg = convertSmilesToSvg(molecule.editedMoleculeSMILES);

            const newRow = {
                index: i + 1,
                moleculeId: molecule.sourceMoleculeID,
                sourceSmiles: molecule.sourceMoleculeSMILES,
                editedSmiles: molecule.editedMoleculeSMILES,
                structure: '',
                admetPlot: '',
                cov: molecule.COV || 'N/A',
            };

            worksheet.addRow(newRow);

            if (editedMoleculeSvg) {
                try {
                    const pngBase64 = await convertSvgToPng(editedMoleculeSvg);
                    const imageId = workbook.addImage({
                        base64: pngBase64,
                        extension: 'png',
                    });
                    worksheet.addImage(imageId, {
                        tl: { col: 4, row: rowIndex - 1 },
                        ext: { width: 150, height: 150 },
                    });
                    worksheet.getRow(rowIndex).height = 110;
                } catch (e) {
                    console.error('Error converting SVG to PNG', e);
                }
            }

            if (molecule.ADMETPlot && molecule.ADMETPlot.radial_plot) {
                try {
                    const admetPngBase64 = await convertSvgToPng(molecule.ADMETPlot.radial_plot);
                    const admetImageId = workbook.addImage({
                        base64: admetPngBase64,
                        extension: 'png',
                    });
                    worksheet.addImage(admetImageId, {
                        tl: { col: 5, row: rowIndex - 1 },
                        ext: { width: 150, height: 150 },
                    });
                } catch (e) {
                    console.error('Error converting AdmetPlot SVG to PNG', e);
                }
            }

            rowIndex++;
        }

        worksheet.columns.forEach((column) => {
            column.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'edited_molecules.xlsx';
            link.click();
        });
    };

    // console.log(tableData)
    const handleDockButtonClick = () => {
        if (selectedMolecules.length === 0) {
            setSnackbarMessage('Please select at least one item to Dock');
            setOpen(true);
        } else {
            const filteredMolecules = selectedMolecules.map(({ ADMETPlot, COV, ...rest }) => rest);
            const smilesList = filteredMolecules.map(molecule => molecule.sourceMoleculeSMILES);
            // console.log('SMILES List to Dock:', JSON.stringify(smilesList));
            setDockSmiles(smilesList)
        }
        setSelectedMolecules([])
    }
    const handleDockSmiles = async (smiles, selectedPdbId) => {
        // Set dock status to 'Docking' for matching SMILES before making the API call
        // const dockingTableData = tableData.molecules.map(item => {
        //     const match = smiles.includes(item.sourceMoleculeSMILES);
        //     if (match) {
        //         return {
        //             ...item,
        //             dock: 'Docking' // Set dock to 'Docking' while processing
        //         };
        //     }
        //     return item;
        // });
        // // Merging dockingTableData into tableData.molecules
        // const updatedMolecules = tableData.molecules.map((molecule) => {
        //     const dockingData = dockingTableData.find(
        //         (dock) => dock.sourceMoleculeID === molecule.sourceMoleculeID
        //     );
        //     return dockingData ? { ...molecule, ...dockingData } : molecule;
        // });

        // // Updating tableData with merged molecules
        // tableData.molecules = updatedMolecules;

        // console.log('Updated tableData:', tableData);
        // // Update the table to reflect the 'Docking' status
        // setTableData(tableData);
        // // console.log(dockingTableData)
        // // console.log("Docking with SMILES:", smiles);
        // // console.log("Docking with selected PDB ID:", selectedPdbId);
        // // console.log("PROTEIN ID:", target);

        const exhaustiveness = dockParameters.exhaustiveness || 8;
        const cpus = dockParameters.cpus || 2;

        // Construct the payload
        const Dock = {
            uniprot_acc: target,
            pdb_id: selectedPdbId,
            exhaustiveness,
            cpus,
            smiles_list: smiles
        };
        const payload = {
            email: UserData.email,
            conversationID: conversation.conversationID,
            queryID: conversation.queryID,
            DockValues: Dock,
        };

        console.log(payload)

        try {
            const response = await fetch(`${BACKEND_URL}/auth/save-dock-inEdited`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });
            const result = await response.json();
            // Handle the response from the server
            console.log(result)
            // console.log(tableData)
            // If the update is successful, modify the table data to show "View" in the dock column
            if (result.status) {
                const updatedData = {
                    ...tableData,  // Copy existing data
                    molecules: tableData.molecules.map((molecule) =>
                        molecule.sourceMoleculeID === payload.sourceMoleculeID
                            ? { ...molecule, dock: "View", dockDetails: result.dock }  // Update dock
                            : molecule
                    ),
                };
                setTableData(updatedData);  // Update state to trigger re-render
            }
            setSnackbarMessage('Dock result updated successfully')
            setOpen(true);
            setTimeout(() => {
                fetchTableData();
            }, 1000);
            // console.log(tableData)
        } catch (error) {
            console.error('Error:', error);
        }
        setDockSmiles(null)

    };

    const handleCellClick = (molecule) => {
        setCurrentDockData(molecule.dock.dockPopup); // what if it is array
        setCurrentDockSmile(molecule.sourceMoleculeSMILES);
        // setCurrentPdb_id(molecule.dock.dockPopup.pdb_id)
        // console.log(molecule.dock.dockData)
        // console.log(molecule.sourceMoleculeSMILES)
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        // setCurrentDockData([]);
    };

    return (
        <div className="container mx-auto px-4 my-2">
            {/* <button
                className="mb-4 px-3 py-1 border border-gray-500 rounded hover:bg-gray-200 transition duration-200"
                onClick={() => navigate('/profile', { state: { activeTab: 'Edited Molecules' } })}
            >
                Back
            </button> */}
            <MdOutlineArrowBackIos className="my-4"
                title='Back'
                onClick={handleBackNavigation} />

            <h2 className="text-xl font-semibold mb-2">{conversation.queryName}</h2>
            <p className="font-semibold">
                No. of Edited SMILES: <span className="font-normal">{conversation.molecules.length}</span>
            </p>
            <div className="flex justify-end mt-2 space-x-2 px-2">
                <button
                    className="px-3 py-1 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                    onClick={handleDownload}
                    disabled={loading}
                >
                    <AiOutlineDownload title="Download" className="mr w-5 h-5 text-gray-700" />
                    {/* <span className="text-gray-800 font-medium">Download XLSX</span> */}
                </button>
                <button
                    className="px-3 py-1 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                    onClick={handlePredict}
                >
                    {/* <FiActivity className="mr-2 w-5 h-5 text-gray-700" /> */}
                    <span className="text-gray-800 font-medium">Predict</span>
                </button>
                <button
                    className="px-3 py-1 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
                    onClick={handleDockButtonClick}
                >
                    <span className="text-gray-800 font-medium">Dock</span>
                </button>
            </div>
            <div className="table-container p-2 overflow-x-auto overflow-y-auto max-h-[500px] w-full custom-scrollbar" >
                <table className="min-w-full table-auto border-collapse">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '50px' }}>#</th>
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '100px' }}>Select</th>
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '100px' }}>MoleculeID</th>
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '250px' }}>Source SMILES</th>
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '250px' }}>Edited SMILES</th>
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '250px' }}>Edited SMILE Structures</th>
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '250px' }}>AdmetPlot</th>
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '250px' }}>COV</th>
                            <th className="border px-4 py-2 text-left font-semibold" style={{ width: '250px' }}>Dock</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.molecules.map((molecule, moleculeIdx) => {
                            const editedMoleculeSvg = convertSmilesToSvg(molecule.editedMoleculeSMILES);
                            return (
                                <tr key={molecule._id} className="hover:bg-gray-100">
                                    <td className="border px-4 py-2 text-sm text-gray-700">{moleculeIdx + 1}</td>
                                    <td className="border px-4 py-2 text-sm">
                                        <input
                                            type="checkbox"
                                            checked={selectedMolecules.includes(molecule)}
                                            onChange={() => handleCheckboxChange(molecule)}
                                        />
                                    </td>
                                    <td className="border px-4 py-2 text-sm text-blue-500 cursor-pointer">
                                        {molecule.sourceMoleculeID}
                                    </td>
                                    <td className="border px-4 py-2 text-sm text-blue-500 cursor-pointer">
                                        <div className="flex items-center">
                                            <div className="overflow-x-auto max-w-[150px] whitespace-nowrap custom-scrollbar p-2">
                                                {molecule.sourceMoleculeSMILES}
                                            </div>
                                            <FiClipboard
                                                className="ml-2 cursor-pointer text-gray-500 hover:text-gray-800"
                                                onClick={() => handleCopyToClipboard(molecule.sourceMoleculeSMILES)}
                                                title="Copy SMILES"
                                            />
                                        </div>
                                    </td>

                                    <td
                                        className="border px-4 py-2 text-sm text-blue-500 cursor-pointer"
                                    // onClick={() => handleEditSmiles(molecule)}
                                    >
                                        <div className="flex items-center">
                                            <div className="overflow-x-auto max-w-[150px] whitespace-nowrap custom-scrollbar p-2">
                                                {molecule.editedMoleculeSMILES}
                                            </div>
                                            <FiClipboard
                                                className="ml-2 cursor-pointer text-gray-500 hover:text-gray-800"
                                                onClick={() => handleCopyToClipboard(molecule.editedMoleculeSMILES)}
                                                title="Copy SMILES"
                                            />
                                        </div>
                                    </td>
                                    <td className="border px-4 py-2 item-center text-sm text-gray-700">
                                        {editedMoleculeSvg && (
                                            <img
                                                src={`data:image/svg+xml;base64,${editedMoleculeSvg}`}
                                                alt="Molecule SVG"
                                                style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                                onClick={() => handleShowPopup(molecule.editedMoleculeSMILES)}
                                            />
                                        )}
                                    </td>
                                    <td className="border px-4 py-2 text-sm text-gray-700">
                                        {/* {molecule.ADMETPlot ? molecule.ADMETPlot.join(', ') : 'N/A'} */}
                                        {molecule.ADMETPlot ? (
                                            <img
                                                src={`data:image/svg+xml;base64,${molecule.ADMETPlot.radial_plot}`}
                                                alt="Radial Plot"
                                                style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setSelectedAdmet(molecule.ADMETPlot);
                                                }
                                                }
                                            />
                                        ) : (<p className='text-center'>-</p>)}
                                    </td>
                                    <td className="border px-4 py-2 text-sm text-gray-700">
                                        {molecule.COV === -1 ? <span className="text-yellow-500">Insignificant</span> : molecule.COV != null ? molecule.COV.toFixed(3) : 'N/A'}
                                    </td>
                                    {/* <td
                                        className={`border px-4 py-2 text-sm ${molecule.dock === 'View'
                                            ? 'text-blue-500 underline cursor-pointer'
                                            : 'text-gray-700'
                                            }`}
                                        onClick={() => handleCellClick(molecule)}
                                    >
                                        {molecule.dock}
                                    </td> */}
                                    <td className="border px-4 py-2 text-sm text-gray-700">

                                        {molecule.dock ? (
                                            <button className="text-blue-500 underline" onClick={() => handleCellClick(molecule)}>View</button>
                                        ) : (
                                            "N/A"
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {loading && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="text-white text-2xl font-bold">Predicting...</div>
                </div>
            )}

            {/* Popup for displaying larger structure */}
            <Dialog
                open={popupOpen}
                onClose={() => setPopupOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle >
                    <span className='bold'> Smile : </span>{popupContent.smiles}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setPopupOpen(false)}
                        aria-label="close"
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {popupContent.svg && (
                        <div className="flex justify-center items-center w-full h-auto"
                            dangerouslySetInnerHTML={{ __html: atob(popupContent.svg) }}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            {selectedAdmet && (
                <AdmetPopup
                    admet={selectedAdmet}
                    // drugName={selectedDrugName}
                    onClose={() => setSelectedAdmet(null)}
                />
            )}

            {editorOpen && (
                <MoleculeEditorPopup
                    className='z-20'
                    smiles={currentMolecule.editedMoleculeSMILES}
                    onSave={handleSaveMolecule}
                    onClose={() => setEditorOpen(false)}
                />
            )}

            {dockSmiles && (
                <DockingPopup
                    proteinID={target}
                    smilesList={dockSmiles}
                    onDock={(smiles, selectedPdbId) => {
                        setSelectedPdbId(selectedPdbId);
                        handleDockSmiles(smiles, selectedPdbId);
                    }}
                    onClose={() => setDockSmiles(null)}
                />
            )}
            {isPopupOpen && (
                <DockPopupInEdit
                    dockData={currentDockData}
                    dockSmile={currentDocksmile}
                    onClose={closePopup}
                    selectedPdbId={selectedPdbId} />
            )}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default PredictEditedMol;

// {dockingResult && (<>
//     <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50">
//         <div
//             className="bg-white p-4 rounded shadow-lg relative"
//             style={{ width: popupWidth }} // Apply dynamic width
//         >
//             {/* Close button */}
//             <button
//                 className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
//                 // onClose={()=> setClose(true)}
//                 onClick={() => setDockingResult(null)}
//             >
//                 &times;
//             </button>

//             <div className="text-lg font-bold mb-4">Choose the structure for docking</div>

//             {/* Display error message if any */}
//             {errorMessage && (
//                 <div className="text-red-500 text-center">
//                     {errorMessage}
//                 </div>
//             )}

//             {/* Display PDB details if available */}
//             {dockingResult && dockingResult.length > 0 ? (<>
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                     {dockingResult.map((pdb, index) => (
//                         <div
//                             key={index}
//                             onClick={() => handleClick(pdb.pdb_id)}  // Single click to select
//                             className={`border p-4 rounded shadow-sm cursor-pointer transition duration-200
//                     ${selectedPdbId === pdb.pdb_id ? 'bg-blue-200 border-blue-400' : 'hover:bg-gray-100'}
//                     `}
//                             title={selectedPdbId === pdb.pdb_id ? 'Selected' : 'Click to select'}
//                         // onMouseEnter={() => handleMouseEnter(pdb.pdb_id)}
//                         // onMouseLeave={handleMouseLeave}
//                         >
//                             <h3 className="text-lg font-bold mb-2">{pdb.pdb_id}{pdb.best_coverage ? " (Best resolution coverage) " : pdb.best_structure ? " (Best resolution structure) " : ""}</h3>
//                             <p><strong>pdb_id:</strong> {pdb.pdb_id}</p>
//                             <p><strong>Resolution:</strong> {pdb.resolution} Å</p>
//                             <p><strong>Coverage:</strong> {pdb.coverage}</p>
//                             <p><strong>Experimental Method:</strong> {pdb.experimental_method}</p>
//                             <p><strong>Chain ID:</strong> {pdb.chain_id}</p>
//                             <p><strong>Start-End:</strong> {pdb.start} - {pdb.end}</p>
//                             <p><strong>UniProt Range:</strong> {pdb.uniprot_start} - {pdb.uniprot_end}</p>
//                         </div>
//                     )
//                     )}
//                 </div>
//                 <button
//                     onClick={handleDock}
//                     className={`px-3 py-1 my-2 border rounded transition duration-200
//                     ${dockButtonEnabled ? 'bg-[#FE4202] text-white' : 'bg-gray-300 text-gray-600 cursor-not-allowed'}
//                     `}
//                     disabled={!dockButtonEnabled}
//                     title={dockButtonEnabled ? 'Dock selected structure' : 'Select a structure to enable docking'}
//                 >
//                     Dock
//                 </button>
//             </>
//             ) : (
//                 !errorMessage && <p className="text-center">No docking data available for this protein.</p>
//             )}

//         </div>
//     </div>
//     </>
// )}
