// Indexed DB

import Dexie from 'dexie';

const db = new Dexie('PredictDatabase');

db.version(1).stores({
  proteins: 'id, name, data, timestamp',
});

db.open().catch((err) => {
  console.error('Failed to open db:', err.stack || err);
});

export default db;