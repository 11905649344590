import React, { useState, useEffect } from 'react';
import logo from '../../Assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignUpPage = () => {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [allowedDomains, setAllowedDomains] = useState([]);

  const [passwordStrength, setPasswordStrength] = useState('');
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const hasNumber = /\d/;
  const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/;

  const checkPasswordStrength = (password) => {

    if (password.length === 0) {
      return '';
    } else if (password.length < 4) {
      return 'Weak : Must contain at least 8 characters';
    } else if (password.length < 8 || !hasNumber.test(password) || !hasSymbol.test(password)) {
      return 'Medium : Must contain symbols and numbers';
    } else {
      return 'Strong : Your password is looking good';
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(checkPasswordStrength(newPassword));
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 'Weak : Must contain at least 8 characters':
        return 'text-red-500';
      case 'Medium : Must contain symbols and numbers':
        return 'text-yellow-500';
      case 'Strong : Your password is looking good':
        return 'text-green-500';
      default:
        return '';
    }
  };


  useEffect(() => {
    // Fetch allowed domains from your API when component mounts
    axios.get(`${BACKEND_URL}/domains`)
      .then(response => {
        const domains = response.data.map(entry => entry.domains).flat();
        setAllowedDomains(domains);
      })
      .catch(error => {
        console.error('Error fetching allowed domains:', error);
      });
  }, [BACKEND_URL]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Validate email domain
    const emailDomain = email.substring(email.lastIndexOf('@') + 1);
    if (!allowedDomains.includes(emailDomain)) {
      setError('Accepts only academic accounts.');
      return;
    }

    // Validate password
    if (password.length < 8 || !hasNumber.test(password) || !hasSymbol.test(password)) {
      setError('Password must be at least 8 characters long and contain at least a symbol and a number.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);

    axios.post(`${BACKEND_URL}/auth/signup`, {
      username: userName,
      email: email,
      password: password
    })
      .then(response => {
        setLoading(false);
        console.log(response);
        if (response.data.status) {
          navigate(`/confirm?email=${email}`);
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Signup error:', error);
        if (error.response && error.response.status === 409) {
          setError('Email already exists. Please use a different email.');
        } else {
          setError('An error occurred. Please try again.');
        }
      });
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Covaln Logo" className="h-12" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Enter your name"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="example@iiitd.ac.in"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6 relative">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="********"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={password}
              onChange={handlePasswordChange}
            />
            <div
              className="absolute right-3 bottom-1 transform -translate-y-1/2 cursor-pointer text-gray-400"
              title={showPassword ? 'Hide password' : 'Show password'}
              onMouseDown={(e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
            
          </div>
          {password && (
            <div className={`text-sm mt-2 ${getPasswordStrengthColor()} mb-4`}>
              {passwordStrength}
            </div>
          )}
          <div className="mb-6 relative">
            <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">Re-enter Password</label>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="********"
              className="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-[#FE4202] border-b-2 border-gray-300"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div
              className="absolute right-3 bottom-1 transform -translate-y-1/2 cursor-pointer text-gray-400"
              onMouseDown={(e) => {
                e.preventDefault();
                setShowConfirmPassword(!showConfirmPassword)
              }}
              // onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {error && <p className="text-[#FE4202] text-sm mb-4">{error}</p>}
          <div className="text-center text-gray-600 text-sm mb-4">
            By clicking Sign Up, you agree to our <a href="#/" className="text-[#FE4202] hover:underline">Terms</a>, <a href="#/" className="text-[#FE4202] hover:underline">Privacy Policy</a> and <a href="#/" className="text-[#FE4202] hover:underline">Cookies Policy</a>.
          </div>
          <div className="flex items-center justify-center">
            <button type="submit" className="bg-white text-[#FE4202] font-bold py-2 px-4 border border-[#FE4202] rounded focus:outline-none focus:shadow-outline hover:bg-[#FE4202] hover:text-white transition-colors duration-300" disabled={loading}>
              {loading ? 'Signing Up...' : 'Sign Up'}
            </button>
          </div>
          <div className="text-center mt-4">
            <p className="text-gray-600 text-sm">Already have an account? <Link to="/login" className="text-[#FE4202] hover:underline">Login</Link></p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpPage;
