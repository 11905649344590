import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import EditedMolecules from './EditedMolecules';
import Settings from './Settings';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const UserProfilePage = () => {
  const location = useLocation();
  const { username, email, name } = useSelector((state) => state.UserDetails);

  const [profileData, setProfileData] = useState({
    name: '',
    username: '',
    email: '',
    image: '',
  });
  const [originalProfileData, setOriginalProfileData] = useState({
    name: '',
    username: '',
    email: '',
    image: '',
  });
  const [profileImage, setProfileImage] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const initialTab = location.state?.activeTab === 'Edited Molecules' ? 1 : 0;

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/auth/profile`)
      .then((response) => {
        if (response.data.status) {
          const { name, username, email, image } = response.data.profile;
          const fetchedProfile = {
            name: name || '',
            username: username || '',
            email: email || '',
            image: image || '',
          };
          setProfileData(fetchedProfile);
          setOriginalProfileData(fetchedProfile); // Set original profile data
        }
      })
      .catch((error) => {
        console.error('Error fetching user profile:', error);
      });
  }, [BACKEND_URL]);

  const handleProfileUpdate = () => {
    // Check if name has changed
    const isNameChanged = profileData.name !== originalProfileData.name;

    // Check if a new profile image has been selected
    const isImageChanged = profileImage !== null;

    if (!isNameChanged && !isImageChanged) {
      setSnackbarMessage('No changes to update.');
      setSnackbarOpen(true);
      return; // Exit the function early
    }

    const formData = new FormData();
    formData.append('name', profileData.name);

    if (profileImage) {
      formData.append('profileImage', profileImage);
    }

    axios
      .post(`${BACKEND_URL}/auth/update-profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.status) {
          setProfileData(response.data.profile);
          setOriginalProfileData(response.data.profile); // Update original profile data
          setProfileImage(null);
          setSnackbarMessage('Profile updated successfully!');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(`Error: ${response.data.message}`);
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
        setSnackbarMessage('An error occurred while updating the profile.');
        setSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      const imageUrl = URL.createObjectURL(file);
      setProfileData({ ...profileData, image: imageUrl });
    }
  };

  const tabs = ['User Profile', 'Edited Molecules', 'Settings'];

  // Determine if there are changes
  const hasChanges =
    profileData.name !== originalProfileData.name || profileImage !== null;

  return (
    <div className="container mx-auto px-4 my-4">
      <Tab.Group defaultIndex={initialTab}>
        <Tab.List className="flex p-1 space-x-1 rounded-xl">
          {tabs.map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium focus:outline-none ${
                  selected
                    ? 'text-[#FE4202] border-b-2 border-[#FE4202]'
                    : 'hover:text-[#FE4202]'
                }`
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="mt-2">
          {tabs.includes('User Profile') && (
            <Tab.Panel className="bg-white rounded-xl p-3 shadow min-h-[200px]">
              <h1 className="text-2xl font-semibold mb-4">User Profile</h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <img
                    src={profileData.image}
                    alt="Profile"
                    className="rounded-full w-24 h-24 object-cover mb-4"
                  />
                  <input type="file" accept="image/*" onChange={handleImageChange} />
                </div>
                <div className="col-span-1 sm:col-span-2 grid grid-cols-1 gap-4">
                  <div>
                    <label className="font-semibold">Name</label>
                    <input
                      type="text"
                      value={profileData.name}
                      className="border rounded px-2 py-1 w-full"
                      onChange={(e) =>
                        setProfileData({ ...profileData, name: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <label className="font-semibold">Username</label>
                    <input
                      type="text"
                      value={profileData.username}
                      className="border rounded px-2 py-1 w-full"
                      disabled
                    />
                  </div>
                  <div>
                    <label className="font-semibold">Email</label>
                    <input
                      type="text"
                      value={profileData.email}
                      className="border rounded px-2 py-1 w-full"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <button
                className={`px-3 py-1 my-5 border rounded transition duration-200 ${
                  hasChanges
                    ? 'border-[#FE4202] hover:bg-[#FE4202] hover:border-collapse hover:text-white'
                    : 'bg-gray-300 cursor-not-allowed'
                }`}
                onClick={handleProfileUpdate}
                disabled={!hasChanges}
                title={!hasChanges ? 'No edits to save' : ''} // Add a tooltip for the disabled state
              >
                Save Changes
              </button>
            </Tab.Panel>
          )}

          {tabs.includes('Edited Molecules') && (
            <Tab.Panel>
              <EditedMolecules />
            </Tab.Panel>
          )}

          {tabs.includes('Settings') && (
            <Tab.Panel>
              <Settings />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>

      {/* Snackbar Component */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.startsWith('Error') ? 'error' : snackbarMessage === 'No changes to update.' ? 'info' : 'success'}
          elevation={6}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default UserProfilePage;