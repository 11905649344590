import React from 'react';

const HelpPage = () => {
  return (
    <iframe
      src="/docs/index.html"
      title="How to use CovalN"
      style={{
        width: '100%',
        height: '100vh',
        border: 'none'
      }}
    ></iframe>
  );
};

export default HelpPage;