import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiClipboard } from 'react-icons/fi';
import Tooltip from '@mui/material/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import ReactPaginate from 'react-paginate';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProteins } from '../../redux/Actions/action';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ExcelJS from 'exceljs';
import { AiOutlineDownload } from 'react-icons/ai';
import MoleculeViewer from './MolecularViewer';
import ProteinDetailsPopup from './ProteinDetailsPopup';

const itemsPerPage = 7;

const TableViewProtein = ({ data }) => {
  // console.log(data)
  const columns = ['Protein ID', 'Sequence', 'Symbol', 'Target Name', 'Structure'];

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedProtein, setSelectedProtein] = useState(null);
  const [selectedProteinStruc, setSelectedProteinStruc] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const ProteinsData = useSelector((state) => state.Proteins);
  const [loading, setLoading] = useState(true);
  const [showViewer, setShowViewer] = useState(false);
  const [proteinIdPopup, setProteinIdPopup] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchProteins())
      .finally(() => setLoading(false));
  }, [dispatch]);

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  // const handleViewStructure = (proteinId) => {
  //   const protein = ProteinsData.find((item) => item.PROTEIN === proteinId);
  //   setSelectedProteinStruc(protein);
  //   setOpenDialog(true);
  // };

  const handleCloseModal = () => {
    setOpenDialog(false);
    setSelectedProtein(null);
  };

  const filteredData = data.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const displayData = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handleProteinClick = (proteinId) => {
    // const protein = ProteinsData.find(item => item.PROTEIN === proteinId);
    setProteinIdPopup(proteinId);
    setSnackbarMessage(`Details of Protein ID: ${proteinId}`);
    setOpen(true);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Protein Data');
    const excelColumns = [
      { header: 'Protein ID', key: 'proteinId', width: 20 },
      { header: 'Sequence', key: 'sequence', width: 30 },
      { header: 'Symbol', key: 'symbol', width: 20 },
      { header: 'Target Name', key: 'targetName', width: 30 },
      { header: 'Structure', key: 'structure', width: 50 },
    ];
    worksheet.columns = excelColumns;

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    for (let row of filteredData) {
      const protein = ProteinsData.find((item) => item.PROTEIN === row.proteinId);
      let structureData = '';
      if (protein && protein.structureData) {
        structureData = protein.structureData;
      } else {
        structureData = 'Structure data not available';
      }
      const newRow = {
        proteinId: row.proteinId,
        sequence: row.sequence,
        symbol: row.symbol,
        targetName: row.targetName,
        structure: structureData,
      };
      const excelRow = worksheet.addRow(newRow);
      excelRow.height = 80;
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'protein_data.xlsx';
      link.click();
    });
  };

  const handleCopyToClipboard = (smiles) => {
    // console.log(smiles);
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(smiles)
        .then(() => {
          setSnackbarMessage('SMILE copied to clipboard!');
          setOpen(true);
        })
        .catch((err) => {
          // console.error('Failed to copy: ', err);
          setSnackbarMessage('Failed to copy SMILES to clipboard.');
          setOpen(true);
        });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = smiles;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        setSnackbarMessage('SMILES copied to clipboard!');
        // console.log('Fallback: Text copied!');
      } catch (err) {
        // console.error('Fallback copy failed: ', err);
        setSnackbarMessage('Failed to copy SMILES to clipboard.');
      }
      document.body.removeChild(textarea);
      setOpen(true);
    }
  };

  const handleClosePopup = () => {
    setProteinIdPopup(null);
};

  if (loading) {
    return <div className="text-xl font-bold mb-4 text-gray-400">Loading...</div>;
  }

  return (
    <>
      {displayData.length > 0 ? (
        <>
          <div>
            <div className="flex justify-between items-center mb-2">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border p-1.5 mr-3 rounded-md"
              />
              <button
                onClick={handleDownload}
                className="px-3 py-1 bg-[#EEEFF2] rounded flex items-center hover:bg-[#DDE0E5] transition duration-200"
              >
                <AiOutlineDownload className="mr w-5 h-5 text-gray-700" title='Download' />
              </button>

            </div>
            <div className="overflow-x-auto max-h-[450px] custom-scrollbar">
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="bg-[#EEEFF2]">
                    {columns.map((col) => (
                      <th key={col} className="px-4 py-2 border text-left text-sm font-medium text-[#0F1D40]">
                        <div className="flex items-center justify-between">
                          <span>{col}</span>
                          <Tooltip title={`Info about ${col}`} placement="top">
                            <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-400 cursor-pointer uniform-icon" />
                          </Tooltip>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {displayData.map((row, idx) => {
                    const protein = ProteinsData.find(item => item.PROTEIN === row.proteinId);
                    const hasStructure = protein && protein.structureData;
                    return (
                      <tr key={idx} className="hover:bg-gray-100">
                        <td
                          className="border px-4 py-2 text-sm text-blue-500 underline cursor-pointer"
                          onClick={() => handleProteinClick(row.proteinId)}
                        >
                          {row.proteinId}
                        </td>
                        <td className="border px-4 py-2 text-sm text-gray-700">
                          <div className="flex items-center">
                            <div className="overflow-x-auto max-w-[150px] whitespace-nowrap custom-scrollbar p-2">
                              {row.sequence}
                            </div>
                            <FiClipboard
                              className="ml-2 cursor-pointer text-gray-500 hover:text-gray-800"
                              onClick={() => handleCopyToClipboard(row.sequence)}
                              title="Copy sequence"
                            />
                          </div></td>
                        <td className="border px-4 py-2 text-sm text-gray-700">{row.symbol}</td>
                        <td className="border px-4 py-2 text-sm text-gray-700">{row.targetName}</td>
                        {/* <td className="border px-4 py-2 text-sm text-gray-700">
                        {hasStructure ? (
                          <button
                            onClick={() => handleViewStructure(row.proteinId)}
                            className="text-blue-500 underline"
                          >
                            View
                          </button>
                        ) : (
                          <span>N/A</span>
                        )}
                      </td> */}
                        {/* <td className="border px-4 py-2 text-sm text-gray-700">{row.structure}</td> */}
                        <td className="border px-4 py-2 text-sm text-gray-700">
                          {row.structure !== "N/A" ? (
                            <>
                              <button
                                className="text-blue-500 hover:underline"
                                onClick={() => setShowViewer(true)}
                              >
                                View
                              </button>
                              {/* Conditionally render MoleculeViewer in a popup or modal */}
                              {showViewer && (
                                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                                  <div className="bg-white p-4 rounded shadow-lg">
                                    <MoleculeViewer pdb={row.proteinId} />
                                    <button
                                      className="mt-2 px-4 py-2 bg-red-500 text-white rounded"
                                      onClick={() => setShowViewer(false)}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            "N/A"
                          )}

                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <ReactPaginate
              previousLabel={<FiChevronLeft />}
              nextLabel={<FiChevronRight />}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={'flex justify-end mt-4 items-center'}
              pageClassName={'mx-1'}
              activeClassName={'font-bold'}
              previousClassName={'mr-4'}
              nextClassName={'ml-4'}
              breakLabel={'...'}
              breakClassName={'mx-1'}
            />
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <MuiAlert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>

            <Dialog
              // open={Boolean(selectedProtein)}
              open={openDialog}
              onClose={handleCloseModal}
              maxWidth="lg"
              fullWidth
            >
              <DialogTitle>Protein Structure</DialogTitle>
              <DialogContent className="w-50 h-full">
                {selectedProteinStruc && <MoleculeViewer pdb={selectedProteinStruc} />}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {proteinIdPopup && (
                <ProteinDetailsPopup className="z-0" proteinId={proteinIdPopup} onClose={handleClosePopup} />
            )}
         
          </div>
        </>
      ) : (<>
        <div className="text-xl font-bold mb-4 text-gray-400">No proteins found to be associated for the given disease </div>
      </>)}


    </>
  );
};

export default TableViewProtein;